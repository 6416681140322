// 工具类别
export const toolCategory = [
  { ID: 0, name: "Video Editor" },
  { ID: 1, name: "Converters" },
  { ID: 2, name: "Video Tools" },
  { ID: 3, name: "More" },
];
// 工具列表
export const toolList = [
  //视频编辑
  {
    ID: 0,
    categoryID: 1,
    key: "0-0-Editor",
    name: "VideoEditor",
    path: "/VideoEditor",
    domain: "videoeditor",
    functionType: "editor",
    icon: "video.png",
    beforeToolIcon: "video.png",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.png",
    color: "#3D99F5",
    inputFileType: [
      "mp4",
      "avi",
      "webm",
      "wmv",
      "3gp",
      "m4v",
      "mkv",
      "swf",
      "av1",
      "ts",
      "mts",
      "3gp",
      "3g2",
      "m2ts",
      "m2v",
      "mpeg",
      "mov",
      "mpg",
      "mpeg-2",
      "ogv",
      "flv",
      "mjpeg",
      "hevc",
      "vob",
      "mxf",
      "asf",
      "f4v",
      "rm",
      "wtv",
    ],
    outputFileType: ["doc", "docx"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.videoeditor.name",
        default: "Video Editor",
      },

      description: {
        key: "tool.videoeditor.description",
        default:
          " cut, trim, and customize a video, or search related pro clips for various editing.",
      },
      title: {
        key: "tool.videoeditor.title",
        default: "Video Editor",
      },
      subtitle: {
        key: "tool.videoeditor.subtitle",
        default: "The easiest way to combine PDF Files",
      },
      tip: {
        title: {
          key: "tool.videoeditor.tipTitle",
          default: ["How to", "use", "Video Editor"],
        },
        body: [
          {
            title: {
              key: "tool.videoeditor.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.videoeditor.tip1.text",
              default:
                "Make your own video from scratch, edit it and add music — all in one screen! Our seamless video editor allows you to manage the added media easily with the help of multi-track timeline.",
            },
          },
          {
            title: {
              key: "tool.videoeditor.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.videoeditor.tip2.text",
              default:
                "Our editor comes with all the features necessary to create a great video — add any text to your project, and personalize it! You can change font, size, boldness, color, add background and more!",
            },
          },
          {
            title: {
              key: "tool.videoeditor.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.videoeditor.tip3.text",
              default:
                "You can manually change the position of both images and videos creating a picture-in-picture, a split-screen video or a collage of any other composition. It has never been so easy!",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.videoeditor.successfulTitle",
        default: "PDF to Word Convert Successful",
      },
    },
  },
  // 拼接画
  {
    ID: 39,
    categoryID: 4,
    key: "0-0-Collage",
    name: "VideoCollage",
    path: "/VideoCollage/VideoCollageWorkspace",
    domain: "videoCollage",
    functionType: "Collage",
    icon: "icon_pdf_to_word.png",
    beforeToolIcon: "video.png",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.png",
    color: "#3D99F5",

    context: {
      name: {
        key: "tool.videocollage.name",
        default: "Video Collage",
      },
      description: {
        key: "tool.videocollage.description",
        default:
          "Click the function bar on the left, select different layouts, add text and background, adjust the scale, and give play to creativity.",
      },
      title: {
        key: "tool.videocollage.title",
        default: "Video Collage",
      },
      subtitle: {
        key: "tool.videocollage.subtitle",
        default: "The easiest way to combine PDF Files",
      },
      tip: {
        title: {
          key: "tool.videocollage.tipTitle",
          default: ["How to", "use", "Video Collage"],
        },
        body: [
          {
            title: {
              key: "tool.videocollage.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.videocollage.tip1.text",
              default:
                "Make your own video from scratch, edit it and add music — all in one screen! Our seamless video collage allows you to manage the added media easily with the help of multi-track timeline.",
            },
          },
          {
            title: {
              key: "tool.videocollage.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.videocollage.tip2.text",
              default:
                "Our collage comes with all the features necessary to create a great video — add any text to your project, and personalize it! You can change font, size, boldness, color, add background and more!",
            },
          },
          {
            title: {
              key: "tool.videocollage.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.videocollage.tip3.text",
              default:
                "You can manually change the position of both images and videos creating a picture-in-picture, a split-screen video or a collage of any other composition. It has never been so easy!",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.videocollage.successfulTitle",
        default: "PDF to Word Convert Successful",
      },
    },
  },
  // 剪同款
  // 拼接画
  {
    ID: 40,
    categoryID: 1,
    key: "0-0-AutoCut",
    name: "VideoAutoCut",
    path: "/AutoCutTemplate",
    domain: "videocutsame",
    functionType: "AutoCut",
    icon: "video.png",
    beforeToolIcon: "video.png",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.png",
    color: "#3D99F5",

    context: {
      name: {
        key: "tool.videoautocut.name",
        default: "Auto Cut",
      },
      description: {
        key: "tool.videoautocut.description",
        default:
          "Cutting the same video has the ability to generate the same video template. Select local material to upload and synthesize with one button, which is easy to operate and avoids the complex steps of video editing; Support flexible secondary editing, adjust the content and order of the material or some elements in the template, and generate personalized and beautiful videos.",
      },
      title: {
        key: "tool.videoautocut.title",
        default: "Video autocut",
      },
      subtitle: {
        key: "tool.videoautocut.subtitle",
        default: "The easiest way to combine PDF Files",
      },
      tip: {
        title: {
          key: "tool.videoautocut.tipTitle",
          default: ["How to", "use", "Video autocut"],
        },
        body: [
          {
            title: {
              key: "tool.videoautocut.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.videoautocut.tip1.text",
              default:
                "Make your own video from scratch, edit it and add music — all in one screen! Our seamless video autocut allows you to manage the added media easily with the help of multi-track timeline.",
            },
          },
          {
            title: {
              key: "tool.videoautocut.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.videoautocut.tip2.text",
              default:
                "Our autocut comes with all the features necessary to create a great video — add any text to your project, and personalize it! You can change font, size, boldness, color, add background and more!",
            },
          },
          {
            title: {
              key: "tool.videoautocut.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.videoautocut.tip3.text",
              default:
                "You can manually change the position of both images and videos creating a picture-in-picture, a split-screen video or a autocut of any other composition. It has never been so easy!",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.videoautocut.successfulTitle",
        default: "PDF to Word Convert Successful",
      },
    },
  },
  //添加字幕
  {
    ID: 1,
    categoryID: 2,
    key: "1-0-Subtitles",
    name: "AddSubtitles",
    path: "/AddSubtitles",
    domain: "addsubtitles",
    functionType: "editor",
    icon: "addSubtitle.png",
    beforeToolIcon: "addSubtitle.png",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.png",
    color: "#3D99F5",
    inputFileType: ["mp4", "webm", "mov"],
    outputFileType: ["pdf"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.addsubtitles.name",
        default: "Add Subtitles",
      },
      description: {
        key: "tool.addsubtitles.description",
        default: "Subtitle a video manually or with an SRT file.",
      },
      title: {
        key: "tool.addsubtitles.title",
        default: "Add Subtitles",
      },
      subtitle: {
        key: "tool.addsubtitles.subtitle",
        default: "Subtitle a video manually or with an SRT file.",
      },
      tip: {
        title: {
          key: "tool.addsubtitles.tipTitle",
          default: ["How to", "Add Subtitles", "to a Video"],
        },
        body: [
          {
            title: {
              key: "tool.addsubtitles.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.addsubtitles.tip1.text",
              default:
                "Choose which video file you want to add subtitles to. Select from your files, or just drag & drop.",
            },
          },
          {
            title: {
              key: "tool.addsubtitles.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.addsubtitles.tip2.text",
              default:
                "Click ‘Subtitles’ in the sidebar menu and you can start to type your subtitles, ‘Auto Transcribe’, or upload a subtitle file (eg. SRT).",
            },
          },
          {
            title: {
              key: "tool.addsubtitles.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.addsubtitles.tip3.text",
              default:
                "Make any edits to text, font, color, size and timing. Then just hit the ‘Export’ button.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.addsubtitles.successfulTitle",
        default: "Word to PDF Convert Successful",
      },
    },
  },
  //将音频添加到视频
  {
    ID: 2,
    categoryID: 4,
    key: "2-2-Audio",
    name: "AddAudioToVideo",
    path: "/AddAudioToVideo",
    domain: "addaudiotovideo",
    functionType: "editor",
    icon: "audio.png",
    beforeToolIcon: "audio.png",
    arrow: "icon_arrow.png",
    afterToolIcon: "video.png",
    color: "#F23030",
    inputFileType: [
      "mp4",
      "avi",
      "webm",
      "wmv",
      "3gp",
      "m4v",
      "mkv",
      "swf",
      "av1",
      "ts",
      "mts",
      "3gp",
      "3g2",
      "m2ts",
      "m2v",
      "mpeg",
      "mov",
      "mpg",
      "mpeg-2",
      "ogv",
      "flv",
      "mjpeg",
      "hevc",
      "vob",
      "mxf",
      "asf",
      "f4v",
      "rm",
      "wtv",
    ],
    outputFileType: ["pdf"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.addaudiotovideo.name",
        default: "Add Audio to Video",
      },
      description: {
        key: "tool.addaudiotovideo.description",
        default:
          "Add excellent music or audio files to your video in seconds! No editing skills required.",
      },
      title: {
        key: "tool.addaudiotovideo.title",
        default: "Add Audio to Video",
      },
      subtitle: {
        key: "tool.addaudiotovideo.subtitle",
        default: "Reduce the size of your PDF online",
      },
      tip: {
        title: {
          key: "tool.addaudiotovideo.tipTitle",
          default: ["How to", "Add Audio ", "to Video"],
        },
        body: [
          {
            title: {
              key: "tool.addaudiotovideo.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.addaudiotovideo.tip1.text",
              default: "Upload the video you would like to add music to. Just drag & drop, done! ",
            },
          },
          {
            title: {
              key: "tool.addaudiotovideo.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.addaudiotovideo.tip2.text",
              default:
                "You can remove the video’s current audio by adjusting the volume slider. Then click ‘Upload’ > ‘Upload Audio’, to add your music and sound effects.",
            },
          },
          {
            title: {
              key: "tool.addaudiotovideo.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.addaudiotovideo.tip3.text",
              default:
                "Once you are happy with your new video, just click ‘Download’ and you're done!",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.addaudiotovideo.successfulTitle",
        default: "Compress PDF Successful",
      },
    },
  },
  //将照片添加到视频
  {
    ID: 3,
    categoryID: 4,
    key: "1-1-Photo",
    name: "AddPhotoToVideo",
    path: "/AddPhotoToVideo",
    domain: "addphototovideo",
    functionType: "editor",
    icon: "photo.png",
    beforeToolIcon: "photo.png",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.png",
    color: "#FFB700",
    outputFileType: ["pdf"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.addphototovideo.name",
        default: "Add Photo to Video",
      },
      description: {
        key: "tool.addphototovideo.description",
        default: "Add excellent Photo files to your video in seconds! No editing skills required.",
      },
      title: {
        key: "tool.addphototovideo.title",
        default: "Add Photo to Video",
      },
      subtitle: {
        key: "tool.addphototovideo.subtitle",
        default: "The best web app to convert Image to PDF",
      },
      tip: {
        title: {
          key: "tool.addphototovideo.tipTitle",
          default: ["How to", " Put a Picture", "on a Video"],
        },
        body: [
          {
            title: {
              key: "tool.addphototovideo.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.addphototovideo.tip1.text",
              default:
                "Select a video to upload. Just click on ‘Choose Video’. You can also drag and drop your file into the editor.",
            },
          },
          {
            title: {
              key: "tool.addphototovideo.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.addphototovideo.tip2.text",
              default:
                "Click on the plus (+) icon on the bottom-right of the screen or click on Upload on the left menu. Select the image files and click Open. Edit the video and images as you wish.",
            },
          },
          {
            title: {
              key: "tool.addphototovideo.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.addphototovideo.tip3.text",
              default: "You can now download your video. Just click on ‘Export’ and you’re done!",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.addphototovideo.successfulTitle",
        default: "Image to PDF Convert Successful",
      },
    },
  },
  //将贴纸添加到视频
  {
    ID: 4,
    categoryID: 4,
    key: "2-4-Sticker",
    name: "AddStickerToVideo",
    path: "/AddStickerToVideo",
    domain: "addstickertovideo",
    functionType: "editor",
    icon: "sticker.png",
    beforeToolIcon: "sticker.png",
    arrow: "icon_arrow.png",
    afterToolIcon: "video.png",
    color: "#7961F2",
    inputFileType: [
      "mp4",
      "avi",
      "webm",
      "wmv",
      "3gp",
      "m4v",
      "mkv",
      "swf",
      "av1",
      "ts",
      "mts",
      "3gp",
      "3g2",
      "m2ts",
      "m2v",
      "mpeg",
      "mov",
      "mpg",
      "mpeg-2",
      "ogv",
      "flv",
      "mjpeg",
      "hevc",
      "vob",
      "mxf",
      "asf",
      "f4v",
      "rm",
      "wtv",
    ],
    outputFileType: ["pdf"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.addstickertovideo.name",
        default: "Add Sticker to Video",
      },
      description: {
        key: "tool.addstickertovideo.description",
        default: "Add Sticker  files to your video in seconds! No editing skills required. ",
      },
      title: {
        key: "tool.addstickertovideo.title",
        default: "Add Sticker to Video",
      },
      subtitle: {
        key: "tool.addstickertovideo.subtitle",
        default: "The easiest way to combine PDF Files",
      },
      tip: {
        title: {
          key: "tool.addstickertovideo.tipTitle",
          default: ["How to", " Add Stickers ", "to Video"],
        },
        body: [
          {
            title: {
              key: "tool.addstickertovideo.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.addstickertovideo.tip1.text",
              default:
                "Upload the video you want to add stickers to. Just drag & drop, it's super easy.",
            },
          },
          {
            title: {
              key: "tool.addstickertovideo.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.addstickertovideo.tip2.text",
              default:
                "Select from the range of stickers or upload an image of your own (try using your brand's logo!)",
            },
          },
          {
            title: {
              key: "tool.addstickertovideo.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.addstickertovideo.tip3.text",
              default:
                "Once you're happy with your new video, simply click download and it will render in seconds, stickers included!",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.addstickertovideo.successfulTitle",
        default: "Merge PDF Successful",
      },
    },
  },
  //将文本添加到视频
  {
    ID: 5,
    categoryID: 4,
    key: "2-3-Text",
    name: "AddTextToVideo",
    path: "/AddTextToVideo",
    domain: "addtexttovideo",
    functionType: "editor",
    icon: "text.png",
    beforeToolIcon: "text.png",
    arrow: "icon_arrow.png",
    afterToolIcon: "video.png",
    color: "#7961F2",
    inputFileType: [
      "mp4",
      "avi",
      "webm",
      "wmv",
      "3gp",
      "m4v",
      "mkv",
      "swf",
      "av1",
      "ts",
      "mts",
      "3gp",
      "3g2",
      "m2ts",
      "m2v",
      "mpeg",
      "mov",
      "mpg",
      "mpeg-2",
      "ogv",
      "flv",
      "mjpeg",
      "hevc",
      "vob",
      "mxf",
      "asf",
      "f4v",
      "rm",
      "wtv",
    ],
    outputFileType: ["pdf", "zip"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.addtexttovideo.name",
        default: "Add Text to Video",
      },
      description: {
        key: "tool.addtexttovideo.description",
        default: "Add Text  files to your video in seconds! No editing skills required.",
      },
      title: {
        key: "tool.addtexttovideo.title",
        default: "Add Text to Video",
      },
      subtitle: {
        key: "tool.addtexttovideo.subtitle",
        default: "An easy way to extract pages from PDF",
      },
      tip: {
        title: {
          key: "tool.addtexttovideo.tipTitle",
          default: ["How to", " Add Text ", "to a Video"],
        },
        body: [
          {
            title: {
              key: "tool.addtexttovideo.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.addtexttovideo.tip1.text",
              default:
                "Click on ‘Choose Video’ and select your file to upload it to VEED. You can also drag and drop your video from your folder to the editor.",
            },
          },
          {
            title: {
              key: "tool.addtexttovideo.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.addtexttovideo.tip2.text",
              default:
                "Start adding text by clicking on the ‘Text’ tool on the left menu. Choose from the text styles and begin typing. You can add as many texts as you want.",
            },
          },
          {
            title: {
              key: "tool.addtexttovideo.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.addtexttovideo.tip3.text",
              default:
                "Start adding text by clicking on the ‘Text’ tool on the left menu. Choose from the text styles and begin typing. You can add as many texts as you want.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.addtexttovideo.successfulTitle",
        default: "Compress PDF Successful",
      },
    },
  },
  //视频转化成文本
  {
    ID: 6,
    categoryID: 4,
    key: "1-3-Text",
    name: "VideoToText",
    path: "/VideoToText",
    domain: "videototext",
    functionType: "editor",
    icon: "video.png",
    beforeToolIcon: "video.png",
    arrow: "icon_arrow.png",
    afterToolIcon: "text.png",
    color: "#FFB700",
    inputFileType: [
      "mp4",
      "avi",
      "webm",
      "wmv",
      "3gp",
      "m4v",
      "mkv",
      "swf",
      "av1",
      "ts",
      "mts",
      "3gp",
      "3g2",
      "m2ts",
      "m2v",
      "mpeg",
      "mov",
      "mpg",
      "mpeg-2",
      "ogv",
      "flv",
      "mjpeg",
      "hevc",
      "vob",
      "mxf",
      "asf",
      "f4v",
      "rm",
      "wtv",
    ],
    outputFileType: ["pdf"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.videototext.name",
        default: "Video to Text",
      },
      description: {
        key: "tool.videototext.description",
        default: "Quickly convert your Text vide files to video now!",
      },
      title: {
        key: "tool.videototext.title",
        default: "Video to Text",
      },
      subtitle: {
        key: "tool.videototext.subtitle",
        default: "Convert your Excel spreadsheet to PDF",
      },
      tip: {
        title: {
          key: "tool.videototext.tipTitle",
          default: ["How to", "Transcribe Video ", "to Text"],
        },
        body: [
          {
            title: {
              key: "tool.videototext.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.videototext.tip1.text",
              default: "Drag & drop your video files into VEED - or try one of our sample videos.",
            },
          },
          {
            title: {
              key: "tool.videototext.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.videototext.tip2.text",
              default: "Click ‘Subtitles’ and then ‘Auto Transcribe’, then hit the ‘Start’ button.",
            },
          },
          {
            title: {
              key: "tool.videototext.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.videototext.tip3.text",
              default:
                "Once the transcription is finished, click ‘Options’, then under ‘Download Subtitles’ select ‘TXT’ as the format, and press the download icon.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.videototext.successfulTitle",
        default: "Excel to PDF Convert Successful",
      },
    },
  },
  //裁剪视频
  {
    ID: 7,
    categoryID: 2,
    key: "1-2-Crop",
    name: "CropVideo",
    path: "/CropVideo",
    domain: "cropvideo",
    functionType: "editor",
    icon: "crop.png",
    beforeToolIcon: "crop.png",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.png",
    color: "#FF8000",
    inputFileType: ["mp4", "webm", "mov"],
    outputFileType: ["pdf"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.cropvideo.name",
        default: "Crop Video",
      },
      description: {
        key: "tool.cropvideo.description",
        default: "Crop any part of your video",
      },
      title: {
        key: "tool.cropvideo.title",
        default: "Crop Video",
      },
      subtitle: {
        key: "tool.cropvideo.subtitle",
        default: "Crop any part of your video",
      },
      tip: {
        title: {
          key: "tool.cropvideo.tipTitle",
          default: ["How to", "Crop ", "a Video"],
        },
        body: [
          {
            title: {
              key: "tool.cropvideo.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.cropvideo.tip1.text",
              default:
                "Select your video and upload it. You can drag & drop, or choose the file from your computer or mobile device",
            },
          },
          {
            title: {
              key: "tool.cropvideo.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.cropvideo.tip2.text",
              default:
                "Simply click on the video’s image and drag the corners to adjust. Or click ‘Settings’ and choose a preset canvas size (like ‘YouTube’, or ‘Instagram Stories’) to crop automatically",
            },
          },
          {
            title: {
              key: "tool.cropvideo.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.cropvideo.tip3.text",
              default:
                "That’s it. You’re ready to share your video with your friends and followers. Hit ‘Export’ and your video will start to download.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.cropvideo.successfulTitle",
        default: "PPT to PDF Convert Successful",
      },
    },
  },
  //翻转视频
  {
    ID: 8,
    categoryID: 4,
    key: "2-1-Flip",
    name: "FlipVideo",
    path: "/FlipVideo",
    domain: "flipvideo",
    functionType: "editor",
    icon: "flip.png",
    beforeToolIcon: "flip.png",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.png",
    color: "#0FC0C5",
    inputFileType: [
      "mp4",
      "avi",
      "webm",
      "wmv",
      "3gp",
      "m4v",
      "mkv",
      "swf",
      "av1",
      "ts",
      "mts",
      "3gp",
      "3g2",
      "m2ts",
      "m2v",
      "mpeg",
      "mov",
      "mpg",
      "mpeg-2",
      "ogv",
      "flv",
      "mjpeg",
      "hevc",
      "vob",
      "mxf",
      "asf",
      "f4v",
      "rm",
      "wtv",
    ],
    outputFileType: ["pdf"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.flipvideo.name",
        //翻转视频
        default: "Flip Video",
      },
      description: {
        key: "tool.flipvideo.description",
        default: "Flip your video sideways or upside-down online.",
      },
      title: {
        key: "tool.flipvideo.title",
        default: "Flip Video",
      },
      subtitle: {
        key: "tool.flipvideo.subtitle",
        default: "Display, print, and share PDFs online",
      },
      tip: {
        title: {
          key: "tool.flipvideo.tipTitle",
          default: ["How to", "Flip ", "a Video"],
        },
        body: [
          {
            title: {
              key: "tool.flipvideo.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.flipvideo.tip1.text",
              default:
                "Upload steps: Just click on the PDF or drag and drop to upload and then you can download the files you need on the download page.",
            },
          },
          {
            title: {
              key: "tool.flipvideo.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.flipvideo.tip2.text",
              default:
                "Click on your video on the Timeline below. A menu will appear and you will see the Flip buttons—one to flip it left or right, and one to flip it up or down.",
            },
          },
          {
            title: {
              key: "tool.flipvideo.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.flipvideo.tip3.text",
              default: "Click on Export and download your video in your desired format.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.flipvideo.successfulTitle",
        default: "PDF Reader",
      },
    },
  },
  //缩放视频
  {
    ID: 9,
    categoryID: 2,
    key: "2-6-Resize",
    name: "ResizeVideo",
    path: "/ResizeVideo",
    domain: "resizevideo",
    functionType: "editor",
    icon: "resize.png",
    beforeToolIcon: "resize.png",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.png",
    color: "#0FC0C5",
    inputFileType: ["mp4", "webm", "mov"],
    outputFileType: ["pdf"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.resizevideo.name",

        default: "Resize Video",
      },
      description: {
        key: "tool.resizevideo.description",
        default: "Resize your videos for YouTube, Instagram, Twitter, and more ...",
      },
      title: {
        key: "tool.resizevideo.title",
        default: "Resize Video",
      },
      subtitle: {
        key: "tool.resizevideo.subtitle",
        default: "Resize your videos for YouTube, Instagram, Twitter, and more ...",
      },
      tip: {
        title: {
          key: "tool.resizevideo.tipTitle",
          default: ["How to", "Resize your", "Videos"],
        },
        body: [
          {
            title: {
              key: "tool.resizevideo.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.resizevideo.tip1.text",
              default: "You can upload your video files by simply dragging and dropping.",
            },
          },
          {
            title: {
              key: "tool.resizevideo.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.resizevideo.tip2.text",
              default:
                "Click ‘Settings’ then choose the size that you need. Crop your video to make sure it fits perfectly inside the frame’s dimensions.",
            },
          },
          {
            title: {
              key: "tool.resizevideo.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.resizevideo.tip3.text",
              default:
                "And that’s it! You can download your new resized, cropped video content. Nice!",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.resizevideo.successfulTitle",
        default: "Delete PDF Pages Successful",
      },
    },
  },
  //移除背景噪音
  {
    ID: 10,
    categoryID: 4,
    key: "2-5-Noise",
    name: "RemoveBackgroundNoise",
    path: "/RemoveBackgroundNoise",
    domain: "removebackgroundnoise",
    functionType: "editor",
    icon: "removeBackground.png",
    beforeToolIcon: "removeBackground.png",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.png",
    color: "#0FC0C5",
    inputFileType: [
      "mp4",
      "avi",
      "webm",
      "wmv",
      "3gp",
      "m4v",
      "mkv",
      "swf",
      "av1",
      "ts",
      "mts",
      "3gp",
      "3g2",
      "m2ts",
      "m2v",
      "mpeg",
      "mov",
      "mpg",
      "mpeg-2",
      "ogv",
      "flv",
      "mjpeg",
      "hevc",
      "vob",
      "mxf",
      "asf",
      "f4v",
      "rm",
      "wtv",
    ],
    outputFileType: ["pdf"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.removebackgroundnoise.name",
        default: "Remove Background Noise",
      },
      description: {
        key: "tool.removebackgroundnoise.description",
        default: "Quickly Remove noise from the video background.",
      },
      title: {
        key: "tool.removebackgroundnoise.title",
        default: "Remove Background Noise",
      },
      subtitle: {
        key: "tool.removebackgroundnoise.subtitle",
        default: "Realize PDF single page or entire PDF rotation",
      },
      tip: {
        title: {
          key: "tool.removebackgroundnoise.tipTitle",
          default: ["How to", "Remove Background Noise From ", " a Video"],
        },
        body: [
          {
            title: {
              key: "tool.removebackgroundnoise.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.removebackgroundnoise.tip1.text",
              default:
                "Upload your video (or audio) files to web - you can just drag & drop. It’s super-easy.",
            },
          },
          {
            title: {
              key: "tool.removebackgroundnoise.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.removebackgroundnoise.tip2.text",
              default:
                "Click ‘Settings’, then simply press ‘Clean Audio’. The background noise from your video will be removed automatically.",
            },
          },
          {
            title: {
              key: "tool.removebackgroundnoise.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.removebackgroundnoise.tip3.text",
              default:
                "You’re all set! Click ‘Download’, and enjoy your clean video (or audio) as it renders in seconds.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.removebackgroundnoise.successfulTitle",
        default: "Rotate PDF Successful",
      },
    },
  },
  //旋转视频
  {
    ID: 11,
    categoryID: 4,
    key: "0-1-Rotate",
    name: "RotateVideo",
    path: "/RotateVideo",
    domain: "rotatevideo",
    functionType: "editor",
    icon: "rotate.png",
    beforeToolIcon: "rotate.png",
    arrow: "none",
    afterToolIcon: "icon_img.png",
    color: "#FFB700",
    inputFileType: [
      "mp4",
      "avi",
      "webm",
      "wmv",
      "3gp",
      "m4v",
      "mkv",
      "swf",
      "av1",
      "ts",
      "mts",
      "3gp",
      "3g2",
      "m2ts",
      "m2v",
      "mpeg",
      "mov",
      "mpg",
      "mpeg-2",
      "ogv",
      "flv",
      "mjpeg",
      "hevc",
      "vob",
      "mxf",
      "asf",
      "f4v",
      "rm",
      "wtv",
    ],
    outputFileType: ["jpg", "zip"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.rotatevideo.name",
        default: "Rotate Video",
      },
      description: {
        key: "tool.rotatevideo.description",
        default: "Free online tool to rotate, flip, invert any video.",
      },
      title: {
        key: "tool.rotatevideo.title",
        default: "Rotate Video",
      },
      subtitle: {
        key: "tool.rotatevideo.subtitle",
        default: "Extract images or save each page from PDF to JPG",
      },
      tip: {
        title: {
          key: "tool.rotatevideo.tipTitle",
          default: ["How to", "Rotate", " a Video"],
        },
        body: [
          {
            title: {
              key: "tool.rotatevideo.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.rotatevideo.tip1.text",
              default:
                "You can select a file to upload from your computer or phone, wait for the video to upload (it may take a minute, depending on the file size).",
            },
          },
          {
            title: {
              key: "tool.rotatevideo.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.rotatevideo.tip2.text",
              default:
                "Once the video is done uploading, you can click on the image and a rotating button will appear above. Use this button to drag your video into the desired orientation. Or you can go to the ‘Adjust’ tab where you can use the slider or specify an exact angle of rotation.",
            },
          },
          {
            title: {
              key: "tool.rotatevideo.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.rotatevideo.tip3.text",
              default:
                "Once you are satisfied with the changes, click ‘Export’ and it will save the new rotated video to your computer or phone.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.rotatevideo.successfulTitle",
        default: "PDF to JPG Convert Successful",
      },
    },
  },
  //视频压缩
  {
    ID: 12,
    categoryID: 3,
    key: "2-0-Compress",
    name: "VideoCompress",
    path: "/VideoCompress",
    domain: "videocompress",
    functionType: "compress",
    icon: "compress.png",
    beforeToolIcon: "compress.png",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.png",
    color: "#0FC0C5",
    inputFileType: [
      "mp4",
      "avi",
      "webm",
      "wmv",
      "3gp",
      "m4v",
      "mkv",
      "swf",
      "av1",
      "ts",
      "mts",
      "3gp",
      "3g2",
      "m2ts",
      "m2v",
      "mpeg",
      "mov",
      "mpg",
      "mpeg-2",
      "ogv",
      "flv",
      "mjpeg",
      "hevc",
      "vob",
      "mxf",
      "asf",
      "f4v",
      "rm",
      "wtv",
    ],
    outputFileType: ["pdf"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.videocompress.name",
        default: "Video Compress",
      },
      description: {
        key: "tool.videocompress.description",
        default: " Reduce video file size in one click preserving video quality.",
      },
      title: {
        key: "tool.videocompress.title",
        default: "Video Compress",
      },
      subtitle: {
        key: "tool.videocompress.subtitle",
        default: "Reduce video file size in one click preserving video quality.",
      },
      tip: {
        title: {
          key: "tool.videocompress.tipTitle",
          default: ["How to", " Compress ", "a Video"],
        },
        body: [
          {
            title: {
              key: "tool.videocompress.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.videocompress.tip1.text",
              default:
                "Choose your video file. You can drag & drop, or select it from your computer or other device.",
            },
          },
          {
            title: {
              key: "tool.videocompress.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.videocompress.tip2.text",
              default:
                "Select your compression options, or let VEED choose for you. We will automatically select the best quality to file size ratio for your video.",
            },
          },
          {
            title: {
              key: "tool.videocompress.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.videocompress.tip3.text",
              default:
                "Download your new compressed video file and share it with friends & followers. Send it via email, upload it to YouTube and more!",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.videocompress.successfulTitle",
        default: "Video Compress Successful",
      },
    },
  },
  //视频切割
  {
    ID: 13,
    categoryID: 2,
    key: "0-0-Cutter",
    name: "VideoCutter",
    path: "/VideoCutter",
    domain: "videocutter",
    functionType: "editor",
    icon: "cutter.png",
    beforeToolIcon: "cutter.png",
    arrow: "none",
    afterToolIcon: "icon_word_new.png",
    color: "#3D99F5",
    inputFileType: ["mp4", "webm", "mov"],
    outputFileType: ["doc", "docx"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.videocutter.name",
        default: "Video Cutter",
      },

      description: {
        key: "tool.videocutter.description",
        default:
          " Allows you to move the two markers to select the beginning and ending of your clip. ",
      },
      title: {
        key: "tool.videocutter.title",
        default: "Video Cutter",
      },
      subtitle: {
        key: "tool.videocutter.subtitle",
        default:
          "Allows you to move the two markers to select the beginning and ending of your clip.",
      },
      tip: {
        title: {
          key: "tool.videocutter.tipTitle",
          default: ["How to", " Cut (Trim)", " a Video"],
        },
        body: [
          {
            title: {
              key: "tool.videocutter.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.videocutter.tip1.text",
              default: "Select a file, or drag & drop it into the editor.",
            },
          },
          {
            title: {
              key: "tool.videocutter.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.videocutter.tip2.text",
              default:
                "Use the timeline to drag the ends of the video just how you want it. You can also click ‘Split’ to remove the middle part of your video.",
            },
          },
          {
            title: {
              key: "tool.videocutter.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.videocutter.tip3.text",
              default:
                "Hit the ‘Export’ button to finish. Download your file and share it with your friends ",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.videocutter.successfulTitle",
        default: "PDF to Word Convert Successful",
      },
    },
  },
  //视频编辑器效果
  {
    ID: 14,
    categoryID: 4,
    key: "1-0-addSubtitles",
    name: "VideoEditorEffects",
    path: "/VideoEditorEffects",
    domain: "videoeditoreffects",
    functionType: "editor",
    icon: "video.png",
    beforeToolIcon: "video.png",
    arrow: "icon_arrow.png",
    afterToolIcon: "effect.png",
    color: "#3D99F5",
    inputFileType: [
      "mp4",
      "avi",
      "webm",
      "wmv",
      "3gp",
      "m4v",
      "mkv",
      "swf",
      "av1",
      "ts",
      "mts",
      "3gp",
      "3g2",
      "m2ts",
      "m2v",
      "mpeg",
      "mov",
      "mpg",
      "mpeg-2",
      "ogv",
      "flv",
      "mjpeg",
      "hevc",
      "vob",
      "mxf",
      "asf",
      "f4v",
      "rm",
      "wtv",
    ],
    outputFileType: ["pdf"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.videoeditoreffects.name",
        default: "Video Editor Effects",
      },
      description: {
        key: "tool.videoeditoreffects.description",
        default: "Add VHS, Glitch, Filmic effects & more, to your videos online.",
      },
      title: {
        key: "tool.videoeditoreffects.title",
        default: "Video Editor Effects",
      },
      subtitle: {
        key: "tool.videoeditoreffects.subtitle",
        default: "Convert your Word to PDF",
      },
      tip: {
        title: {
          key: "tool.videoeditoreffects.tipTitle",
          default: ["How to", "Add Subtitles ", "to a Video"],
        },
        body: [
          {
            title: {
              key: "tool.videoeditoreffects.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.videoeditoreffects.tip1.text",
              default:
                "Choose which video file you want to add subtitles to. Select from your files, or just drag & drop.",
            },
          },
          {
            title: {
              key: "tool.videoeditoreffects.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.videoeditoreffects.tip2.text",
              default:
                "Click ‘Subtitles’ in the sidebar menu and you can start to type your subtitles, ‘Auto Transcribe’, or upload a subtitle file (eg. SRT).",
            },
          },
          {
            title: {
              key: "tool.videoeditoreffects.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.videoeditoreffects.tip3.text",
              default:
                "Make any edits to text, font, color, size and timing. Then just hit the ‘Export’ button.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.videoeditoreffects.successfulTitle",
        default: "Word to PDF Convert Successful",
      },
    },
  },

  //视频编辑器过度
  {
    ID: 15,
    categoryID: 4,
    key: "1-1-Transition",
    name: "VideoEditorTransition",
    path: "/VideoEditorTransition",
    domain: "videoeditortransition",
    functionType: "editor",
    icon: "transition.png",
    beforeToolIcon: "transition.png",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.png",
    color: "#FFB700",
    outputFileType: ["pdf"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.videoeditortransition.name",
        default: "Video Editor Transition",
      },
      description: {
        key: "tool.videoeditortransition.description",
        default: "Add transition effects to your video clips, free.",
      },
      title: {
        key: "tool.videoeditortransition.title",
        default: "Video Editor Transition",
      },
      subtitle: {
        key: "tool.videoeditortransition.subtitle",
        default: "The best web app to convert Image to PDF",
      },
      tip: {
        title: {
          key: "tool.videoeditortransition.tipTitle",
          default: ["How to", "Add Transitions ", "to Video"],
        },
        body: [
          {
            title: {
              key: "tool.videoeditortransition.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.videoeditortransition.tip1.text",
              default:
                "Upload the video you would like to add effects to. Click on ‘Choose Video’. Select the file or just drag & drop.",
            },
          },
          {
            title: {
              key: "tool.videoeditortransition.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.videoeditortransition.tip2.text",
              default:
                "Click Transitions from the left menu. Select the transition style that you want and drag and drop it in between two clips.",
            },
          },
          {
            title: {
              key: "tool.videoeditortransition.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.videoeditortransition.tip3.text",
              default:
                "Once you are happy with the style of your video, just click the ‘Export’ button and you’re done!",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.videoeditortransition.successfulTitle",
        default: "Image to PDF Convert Successful",
      },
    },
  },

  //合并视频
  {
    ID: 16,
    categoryID: 0,
    key: "2-4-Merge",
    name: "VideoMerge",
    path: "/VideoMerge",
    domain: "videomerge",
    functionType: "editor",
    icon: "merge.png",
    beforeToolIcon: "merge.png",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.png",
    color: "#7961F2",
    inputFileType: ["mp4", "webm", "mov"],
    outputFileType: ["pdf"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.videomerge.name",
        default: "Video Merge",
      },
      description: {
        key: "tool.videomerge.description",
        default: "Instantly upload any number of videos or images and combine them with one click.",
      },
      title: {
        key: "tool.videomerge.title",
        default: "Video Merge",
      },
      subtitle: {
        key: "tool.videomerge.subtitle",
        default: "Instantly upload any number of videos or images and combine them with one click.",
      },
      tip: {
        title: {
          key: "tool.videomerge.tipTitle",
          default: ["How to", "Merge ", "Video"],
        },
        body: [
          {
            title: {
              key: "tool.videomerge.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.videomerge.tip1.text",
              default: "Select videos that you want to merge from the computer.",
            },
          },
          {
            title: {
              key: "tool.videomerge.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.videomerge.tip2.text",
              default: "Drag and drop to change the orders at your will.s",
            },
          },
          {
            title: {
              key: "tool.videomerge.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.videomerge.tip3.text",
              default:
                "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.videomerge.successfulTitle",
        default: "Merge PDF Successful",
      },
    },
  },

  //视频滤镜
  {
    ID: 17,
    categoryID: 4,
    key: "2-3-Filters",
    name: "VideoFilters",
    path: "/VideoFilters",
    domain: "videofilters",
    functionType: "editor",
    icon: "filter.png",
    beforeToolIcon: "filter.png",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.png",
    color: "#7961F2",
    inputFileType: [
      "mp4",
      "avi",
      "webm",
      "wmv",
      "3gp",
      "m4v",
      "mkv",
      "swf",
      "av1",
      "ts",
      "mts",
      "3gp",
      "3g2",
      "m2ts",
      "m2v",
      "mpeg",
      "mov",
      "mpg",
      "mpeg-2",
      "ogv",
      "flv",
      "mjpeg",
      "hevc",
      "vob",
      "mxf",
      "asf",
      "f4v",
      "rm",
      "wtv",
    ],
    outputFileType: ["pdf", "zip"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.videofilters.name",
        default: "Video Filters",
      },
      description: {
        key: "tool.videofilters.description",
        default: "Add filters and effects to your videos online, no account required.",
      },
      title: {
        key: "tool.videofilters.title",
        default: "Video Filters",
      },
      subtitle: {
        key: "tool.videofilters.subtitle",
        default: "An easy way to extract pages from PDF",
      },
      tip: {
        title: {
          key: "tool.videofilters.tipTitle",
          default: ["How to", "Add Filters ", "to Video"],
        },
        body: [
          {
            title: {
              key: "tool.videofilters.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.videofilters.tip1.text",
              default:
                "Click ‘Get Started’ above. Upload your video files to VEED. Select the file(s) or just drag & drop, it’s that easy!",
            },
          },
          {
            title: {
              key: "tool.videofilters.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.videofilters.tip2.text",
              default:
                "Head over to the ‘Filters’ tab and choose from a fantastic range of preset filters. You can adjust colour grading, add effects and more.",
            },
          },
          {
            title: {
              key: "tool.videofilters.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.videofilters.tip3.text",
              default:
                "Looking good? Download your new video by hitting the ‘Export’ button, and you’re done!",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.videofilters.successfulTitle",
        default: "Compress PDF Successful",
      },
    },
  },
  // 视频帧速率转换器
  {
    ID: 18,
    categoryID: 4,
    key: "1-3-Converter",
    name: "VideoFrameRateConverter",
    path: "/VideoFrameRateConverter",
    domain: "videoframerateconverter",
    functionType: "editor",
    icon: "speed.png",
    beforeToolIcon: "speed.png",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.png",
    color: "#FFB700",
    inputFileType: [
      "mp4",
      "avi",
      "webm",
      "wmv",
      "3gp",
      "m4v",
      "mkv",
      "swf",
      "av1",
      "ts",
      "mts",
      "3gp",
      "3g2",
      "m2ts",
      "m2v",
      "mpeg",
      "mov",
      "mpg",
      "mpeg-2",
      "ogv",
      "flv",
      "mjpeg",
      "hevc",
      "vob",
      "mxf",
      "asf",
      "f4v",
      "rm",
      "wtv",
    ],
    outputFileType: ["pdf"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.videoframerateconverter.name",
        default: "Video Frame Rate Converter",
      },
      description: {
        key: "tool.videoframerateconverter.description",
        default: "Change Video Frame Rate, Online & Free - VEED.IO.",
      },
      title: {
        key: "tool.videoframerateconverter.title",
        default: "Video Frame Rate Converter",
      },
      subtitle: {
        key: "tool.videoframerateconverter.subtitle",
        default: "Convert your Excel spreadsheet to PDF",
      },
      tip: {
        title: {
          key: "tool.videoframerateconverter.tipTitle",
          default: ["How to", "Change Video ", "Frame Rate"],
        },
        body: [
          {
            title: {
              key: "tool.videoframerateconverter.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.videoframerateconverter.tip1.text",
              default:
                "Click ‘Get Started’ above. Upload the video whose frame rate you wish to change. Just drag & drop, it's super easy.",
            },
          },
          {
            title: {
              key: "tool.videoframerateconverter.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.videoframerateconverter.tip2.text",
              default:
                "To access the frame rate settings, click on the ‘Export’ button on the top-right of the editor. On the ‘Export Options’ tab, click on “switch to advanced settings”, located on the right side of the menu. This will switch the menu from simple settings to advanced settings. On the Advanced Settings menu, you will see a “framerate limit” tool. Drag the button to the left or right to adjust frame rate. The frame rate limit is 60fps, which is situated to the right end of the button.",
            },
          },
          {
            title: {
              key: "tool.videoframerateconverter.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.videoframerateconverter.tip3.text",
              default:
                "Once you're happy with your new frame rate, click the blue ‘Export Video’ button, and your video will render in seconds.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.videoframerateconverter.successfulTitle",
        default: "Excel to PDF Convert Successful",
      },
    },
  },
  // 视频覆盖
  {
    ID: 19,
    categoryID: 4,
    key: "1-2-Overlay",
    name: "VideoOverlay",
    path: "/VideoOverlay",
    domain: "videooverlay",
    functionType: "editor",
    icon: "overlay.png",
    beforeToolIcon: "overlay.png",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.png",
    color: "#FF8000",
    inputFileType: [
      "mp4",
      "avi",
      "webm",
      "wmv",
      "3gp",
      "m4v",
      "mkv",
      "swf",
      "av1",
      "ts",
      "mts",
      "3gp",
      "3g2",
      "m2ts",
      "m2v",
      "mpeg",
      "mov",
      "mpg",
      "mpeg-2",
      "ogv",
      "flv",
      "mjpeg",
      "hevc",
      "vob",
      "mxf",
      "asf",
      "f4v",
      "rm",
      "wtv",
    ],
    outputFileType: ["pdf"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.videooverlay.name",
        default: "Video Overlay",
      },
      description: {
        key: "tool.videooverlay.description",
        default: "Add video overlays and camera effects to your videos.",
      },
      title: {
        key: "tool.videooverlay.title",
        default: "Video Overlay",
      },
      subtitle: {
        key: "tool.videooverlay.subtitle",
        default: "Convert your PowerPoint to PDF",
      },
      tip: {
        title: {
          key: "tool.videooverlay.tipTitle",
          default: ["How to", "Add Video ", "Overlays"],
        },
        body: [
          {
            title: {
              key: "tool.videooverlay.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.videooverlay.tip1.text",
              default:
                "Upload the video you would like to add effects to. Click on ‘Choose Video’. Select the file or just drag & drop.",
            },
          },
          {
            title: {
              key: "tool.videooverlay.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.videooverlay.tip2.text",
              default:
                "Click ‘Filters’, and select the filters, effects & color grading options you want to apply to your video.",
            },
          },
          {
            title: {
              key: "tool.videooverlay.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.videooverlay.tip3.text",
              default:
                "Once you are happy with the style of your video, just click the ‘Export’ button and you’re done!",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.videooverlay.successfulTitle",
        default: "PPT to PDF Convert Successful",
      },
    },
  },
  //视频拆分
  {
    ID: 20,
    categoryID: 4,
    key: "2-1-Split",
    name: "VideoSplit",
    path: "/VideoSplit",
    domain: "videosplit",
    functionType: "editor",
    icon: "spliter.png",
    beforeToolIcon: "spliter.png",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.png",
    color: "#0FC0C5",
    inputFileType: [
      "mp4",
      "avi",
      "webm",
      "wmv",
      "3gp",
      "m4v",
      "mkv",
      "swf",
      "av1",
      "ts",
      "mts",
      "3gp",
      "3g2",
      "m2ts",
      "m2v",
      "mpeg",
      "mov",
      "mpg",
      "mpeg-2",
      "ogv",
      "flv",
      "mjpeg",
      "hevc",
      "vob",
      "mxf",
      "asf",
      "f4v",
      "rm",
      "wtv",
    ],
    outputFileType: ["pdf"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.videosplit.name",
        default: "Video Splitter",
      },
      description: {
        key: "tool.videosplit.description",
        default:
          "It can cut or truncate a huge AVI/DivX, MPEG I/II, VOB, DAT, WMV, ASF file into small pieces.",
      },
      title: {
        key: "tool.videosplit.title",
        default: "Video Spliter",
      },
      subtitle: {
        key: "tool.videosplit.subtitle",
        default: "Display, print, and share PDFs online",
      },
      tip: {
        title: {
          key: "tool.videosplit.tipTitle",
          default: ["How to", "Split ", "a Video"],
        },
        body: [
          {
            title: {
              key: "tool.videosplit.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.videosplit.tip1.text",
              default:
                "Select your video and upload it to VEED. You can also drag and drop your video file from your folder to the editor after clicking on ‘Choose Video’.",
            },
          },
          {
            title: {
              key: "tool.videosplit.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.videosplit.tip2.text",
              default:
                "Click on the location where you want to split your video or move the slider across the timeline. Press the ‘S’ key on your keyboard or click on the ‘Split’ button. Delete the clips you don’t want.",
            },
          },
          {
            title: {
              key: "tool.videosplit.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.videosplit.tip3.text",
              default: "Click on ‘Export’. Your new video will be saved as an MP4 file.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.videosplit.successfulTitle",
        default: "PDF Reader",
      },
    },
  },
  //视频静音
  {
    ID: 21,
    categoryID: 4,
    key: "2-6-Mute",
    name: "MuteVideo",
    path: "/MuteVideo",
    domain: "mutevideo",
    functionType: "editor",
    icon: "mute.png",
    beforeToolIcon: "mute.png",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.png",
    color: "#0FC0C5",
    inputFileType: [
      "mp4",
      "avi",
      "webm",
      "wmv",
      "3gp",
      "m4v",
      "mkv",
      "swf",
      "av1",
      "ts",
      "mts",
      "3gp",
      "3g2",
      "m2ts",
      "m2v",
      "mpeg",
      "mov",
      "mpg",
      "mpeg-2",
      "ogv",
      "flv",
      "mjpeg",
      "hevc",
      "vob",
      "mxf",
      "asf",
      "f4v",
      "rm",
      "wtv",
    ],
    outputFileType: ["pdf"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.mutevideo.name",
        default: "Mute Video",
      },
      description: {
        key: "tool.mutevideo.description",
        default: "Fast and easy way to mute videos.",
      },
      title: {
        key: "tool.mutevideo.title",
        default: "Mute Video",
      },
      subtitle: {
        key: "tool.mutevideo.subtitle",
        default: "Remove pages from your PDF online",
      },
      tip: {
        title: {
          key: "tool.mutevideo.tipTitle",
          default: ["How to", "delete", "PDF"],
        },
        body: [
          {
            title: {
              key: "tool.mutevideo.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.mutevideo.tip1.text",
              default:
                "Open a video you want to remove sound from, you can do it by choosing it from your device.",
            },
          },
          {
            title: {
              key: "tool.mutevideo.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.mutevideo.tip2.text",
              default:
                "Now just wait for your video to be muted. Don't worry, the process won't take long! ",
            },
          },
          {
            title: {
              key: "tool.mutevideo.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.mutevideo.tip3.text",
              default:
                "It's already done! Playback the muted video clip to make sure it's exactly what you wanted and download it back to your device.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.mutevideo.successfulTitle",
        default: "Delete PDF Pages Successful",
      },
    },
  },
  //视频速度控制
  {
    ID: 22,
    categoryID: 4,
    key: "2-5-Controller",
    name: "VideoSpeedController",
    path: "/VideoSpeedController",
    domain: "videospeedcontroller",
    functionType: "editor",
    icon: "adjustSpeed.png",
    beforeToolIcon: "adjustSpeed.png",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.png",
    color: "#0FC0C5",
    inputFileType: [
      "mp4",
      "avi",
      "webm",
      "wmv",
      "3gp",
      "m4v",
      "mkv",
      "swf",
      "av1",
      "ts",
      "mts",
      "3gp",
      "3g2",
      "m2ts",
      "m2v",
      "mpeg",
      "mov",
      "mpg",
      "mpeg-2",
      "ogv",
      "flv",
      "mjpeg",
      "hevc",
      "vob",
      "mxf",
      "asf",
      "f4v",
      "rm",
      "wtv",
    ],
    outputFileType: ["pdf"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.videospeedcontroller.name",
        default: "Video Speed Controller",
      },
      description: {
        key: "tool.videospeedcontroller.description",
        default: "Speed up or slow down any video.",
      },
      title: {
        key: "tool.videospeedcontroller.title",
        default: "Video Speed Controller",
      },
      subtitle: {
        key: "tool.videospeedcontroller.subtitle",
        default: "Realize PDF single page or entire PDF rotation",
      },
      tip: {
        title: {
          key: "tool.videospeedcontroller.tipTitle",
          default: ["How to", "Change the Speed of ", "a Video"],
        },
        body: [
          {
            title: {
              key: "tool.videospeedcontroller.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.videospeedcontroller.tip1.text",
              default:
                "Select your video from your folder and upload it to VEED. Or drag and drop your file into the editor.",
            },
          },
          {
            title: {
              key: "tool.videospeedcontroller.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.videospeedcontroller.tip2.text",
              default:
                "Choose a speed from the Speed settings under Audio. You can also set your desired field by clicking on ‘Custom’.",
            },
          },
          {
            title: {
              key: "tool.videospeedcontroller.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.videospeedcontroller.tip3.text",
              default:
                "Download your video by clicking on ‘Export’. Your video will be saved as an MP4 file.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.videospeedcontroller.successfulTitle",
        default: "Rotate PDF Successful",
      },
    },
  },
  // 视频导出音频
  {
    ID: 23,
    categoryID: 4,
    key: "0-1-Audio",
    name: "VideoToAudio",
    path: "/VideoToAudio",
    domain: "videotoaudio",
    functionType: "editor",
    icon: "video.png",
    beforeToolIcon: "video.png",
    arrow: "icon_arrow.png",
    afterToolIcon: "audio.png",
    color: "#FFB700",
    inputFileType: [
      "mp4",
      "avi",
      "webm",
      "wmv",
      "3gp",
      "m4v",
      "mkv",
      "swf",
      "av1",
      "ts",
      "mts",
      "3gp",
      "3g2",
      "m2ts",
      "m2v",
      "mpeg",
      "mov",
      "mpg",
      "mpeg-2",
      "ogv",
      "flv",
      "mjpeg",
      "hevc",
      "vob",
      "mxf",
      "asf",
      "f4v",
      "rm",
      "wtv",
    ],
    outputFileType: ["jpg", "zip"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.videotoaudio.name",
        default: "Video to Audio",
      },
      description: {
        key: "tool.videotoaudio.description",
        default: "Quickly convert your Audio files to Video now!",
      },
      title: {
        key: "tool.videotoaudio.title",
        default: "Video to Audio",
      },
      subtitle: {
        key: "tool.videotoaudio.subtitle",
        default: "Extract images or save each page from PDF to JPG",
      },
      tip: {
        title: {
          key: "tool.videotoaudio.tipTitle",
          default: ["How to", "Convert Video", "to Audio"],
        },
        body: [
          {
            title: {
              key: "tool.videotoaudio.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.videotoaudio.tip1.text",
              default:
                "Add a video file by clicking on ‘Select Files’. Select your video or drag and drop it into the box.",
            },
          },
          {
            title: {
              key: "tool.videotoaudio.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.videotoaudio.tip2.text",
              default: "Click Convert button,converts your video to audio  in seconds!",
            },
          },
          {
            title: {
              key: "tool.videotoaudio.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.videotoaudio.tip3.text",
              default: "You can now download your audio. Just click on ‘Export’ and you’re done!",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.videotoaudio.successfulTitle",
        default: "PDF to JPG Convert Successful",
      },
    },
  },

  //视频倒放
  {
    ID: 24,
    categoryID: 4,
    key: "0-0-Reverse",
    name: "ReverseVideo",
    path: "/ReverseVideo",
    domain: "reversevideo",
    functionType: "editor",
    icon: "reverse.png",
    beforeToolIcon: "reverse.png",
    arrow: "none",
    afterToolIcon: "icon_word_new.png",
    color: "#3D99F5",
    inputFileType: [
      "mp4",
      "avi",
      "webm",
      "wmv",
      "3gp",
      "m4v",
      "mkv",
      "swf",
      "av1",
      "ts",
      "mts",
      "3gp",
      "3g2",
      "m2ts",
      "m2v",
      "mpeg",
      "mov",
      "mpg",
      "mpeg-2",
      "ogv",
      "flv",
      "mjpeg",
      "hevc",
      "vob",
      "mxf",
      "asf",
      "f4v",
      "rm",
      "wtv",
    ],
    outputFileType: ["doc", "docx"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.reversevideo.name",
        default: "Reverse Video",
      },

      description: {
        key: "tool.reversevideo.description",
        default: "Instantly add a reverse effect to any video。",
      },
      title: {
        key: "tool.reversevideo.title",
        default: "Reverse Video",
      },
      subtitle: {
        key: "tool.reversevideo.subtitle",
        default: "The easiest way to combine PDF Files",
      },
      tip: {
        title: {
          key: "tool.reversevideo.tipTitle",
          default: ["How to", "Reverse Video", "Easily"],
        },
        body: [
          {
            title: {
              key: "tool.reversevideo.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.reversevideo.tip1.text",
              default:
                "Open Online Video Reverser by Clideo. Upload a video from your computer or mobile device, the program works with the most popular video formats such as MOV, AVI, MP4 and others.",
            },
          },
          {
            title: {
              key: "tool.reversevideo.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.reversevideo.tip2.text",
              default:
                "Once the video is uploaded, change its speed. If you want, you can mute the video. When you’re done with that, click the 'Reverse' button.",
            },
          },
          {
            title: {
              key: "tool.reversevideo.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.reversevideo.tip3.text",
              default: "You can now download your video. Just click on ‘Export’ and you’re done!",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.reversevideo.successfulTitle",
        default: "PDF to Word Convert Successful",
      },
    },
  },
  //GIF转视频
  {
    ID: 25,
    categoryID: 1,
    key: "1-0-GIF",
    name: "GIFToVideo",
    path: "/GIFToVideo",
    domain: "GIFtovideo",
    functionType: "convert",
    icon: "gif.png",
    beforeToolIcon: "gif.png",
    arrow: "icon_arrow.png",
    afterToolIcon: "video.png",
    color: "#3D99F5",
    inputFileType: ["gif"],
    outputFileType: ["mp4"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.GIFtovideo.name",
        default: "GIF to Video",
      },
      description: {
        key: "tool.GIFtovideo.description",
        default: "Quickly convert your GIF files to video now.",
      },
      title: {
        key: "tool.GIFtovideo.title",
        default: "GIF to Video",
      },
      subtitle: {
        key: "tool.GIFtovideo.subtitle",
        default: "Quickly convert your GIF files to video now.",
      },
      tip: {
        title: {
          key: "tool.GIFtovideo.tipTitle",
          default: ["How to", "GIF", "to Video"],
        },
        body: [
          {
            title: {
              key: "tool.GIFtovideo.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.GIFtovideo.tip1.text",
              default:
                "Add a GIF file by clicking on ‘Select Files’. Select your GIF or drag and drop it into the box.",
            },
          },
          {
            title: {
              key: "tool.GIFtovideo.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.GIFtovideo.tip2.text",
              default: "click the 'convert' button to convert to MP4 format.",
            },
          },
          {
            title: {
              key: "tool.GIFtovideo.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.GIFtovideo.tip3.text",
              default: "You can now download your video. Just click on ‘Export’ and you’re done!",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.GIFtovideo.successfulTitle",
        default: "Word to PDF Convert Successful",
      },
    },
  },
  //视频翻译
  {
    ID: 26,
    categoryID: 4,
    key: "2-2-Translator",
    name: "VideoTranslator",
    path: "/VideoTranslator",
    domain: "videotranslator",
    functionType: "editor",
    icon: "translator.png",
    beforeToolIcon: "translator.png",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.png",
    color: "#F23030",
    inputFileType: [
      "mp4",
      "avi",
      "webm",
      "wmv",
      "3gp",
      "m4v",
      "mkv",
      "swf",
      "av1",
      "ts",
      "mts",
      "3gp",
      "3g2",
      "m2ts",
      "m2v",
      "mpeg",
      "mov",
      "mpg",
      "mpeg-2",
      "ogv",
      "flv",
      "mjpeg",
      "hevc",
      "vob",
      "mxf",
      "asf",
      "f4v",
      "rm",
      "wtv",
    ],
    outputFileType: ["pdf"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.videotranslator.name",
        default: "Video Translator",
      },
      description: {
        key: "tool.videotranslator.description",
        default: "Automatically translate your videos into +100 languages online.",
      },
      title: {
        key: "tool.videotranslator.title",
        default: "Compress PDF",
      },
      subtitle: {
        key: "tool.videotranslator.subtitle",
        default: "Reduce the size of your PDF online",
      },
      tip: {
        title: {
          key: "tool.videotranslator.tipTitle",
          default: ["How to", "Translate ", "Videos"],
        },
        body: [
          {
            title: {
              key: "tool.videotranslator.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.videotranslator.tip1.text",
              default:
                "Add a video file by clicking on ‘Choose Video’. Select your video or drag and drop it into the box.",
            },
          },
          {
            title: {
              key: "tool.videotranslator.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.videotranslator.tip2.text",
              default:
                "Click on ‘Subtitles’ to automatically generate subtitles. You can also type manually or upload a subtitle file. Then click ‘Translate’ and select your desired language.",
            },
          },
          {
            title: {
              key: "tool.videotranslator.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.videotranslator.tip3.text",
              default:
                "Check your translations and make any edits to the text. Once you’re happy, download the SRT file or burn the translated subtitles to the video.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.videotranslator.successfulTitle",
        default: "Compress PDF Successful",
      },
    },
  },
  //视频导成GIF图片
  {
    ID: 27,
    categoryID: 1,
    key: "2-0-Gif",
    name: "VideoToGif",
    path: "/VideoToGif",
    domain: "videotogif",
    functionType: "convert",
    icon: "video.png",
    beforeToolIcon: "video.png",
    arrow: "icon_arrow.png",
    afterToolIcon: "gif.png",
    color: "#0FC0C5",
    inputFileType: [
      "mp4",
      "avi",
      "webm",
      "wmv",
      "3gp",
      "m4v",
      "mkv",
      "swf",
      "av1",
      "ts",
      "mts",
      "3gp",
      "3g2",
      "m2ts",
      "m2v",
      "mpeg",
      "mov",
      "mpg",
      "mpeg-2",
      "ogv",
      "flv",
      "mjpeg",
      "hevc",
      "vob",
      "mxf",
      "asf",
      "f4v",
      "rm",
      "wtv",
    ],
    outputFileType: ["gif"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.videotogif.name",
        default: "Video to Gif",
      },
      description: {
        key: "tool.videotogif.description",
        default: "Quickly convert your GIF vide files to video now!",
      },
      title: {
        key: "tool.videotogif.title",
        default: "Video to Gif",
      },
      subtitle: {
        key: "tool.videotogif.subtitle",
        default: "Quickly convert your GIF vide files to video now!",
      },
      tip: {
        title: {
          key: "tool.videotogif.tipTitle",
          default: ["How to", "Create", "a GIF"],
        },
        body: [
          {
            title: {
              key: "tool.videotogif.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.videotogif.tip1.text",
              default:
                "Add a video file by clicking on ‘Select Files’. Select your video or drag and drop it into the box.",
            },
          },
          {
            title: {
              key: "tool.videotogif.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.videotogif.tip2.text",
              default: "Make any edits as you like in clicks.",
            },
          },
          {
            title: {
              key: "tool.videotogif.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.videotogif.tip3.text",
              default: "Save the edited photo/video as GIF and share.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.videotogif.successfulTitle",
        default: "PDF Edit Successful",
      },
    },
  },
  //视频转mp4
  {
    ID: 28,
    categoryID: 1,
    key: "1-1-mp4",
    name: "VideoToMp4",
    path: "/VideoToMp4",
    domain: "videotomp4",
    functionType: "convert",
    icon: "mp4.png",
    beforeToolIcon: "mp4.png",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.png",
    color: "#FFB700",
    inputFileType: [
      "avi",
      "webm",
      "wmv",
      "3gp",
      "m4v",
      "mkv",
      "swf",
      "av1",
      "ts",
      "mts",
      "3gp",
      "3g2",
      "m2ts",
      "m2v",
      "mpeg",
      "mov",
      "mpg",
      "mpeg-2",
      "ogv",
      "flv",
      "mjpeg",
      "hevc",
      "vob",
      "mxf",
      "asf",
      "f4v",
      "rm",
      "wtv",
    ],
    outputFileType: ["mp4"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.videotomp4.name",
        default: "Video to mp4",
      },
      description: {
        key: "tool.videotomp4.description",
        default: " Quickly convert your video files to MP4 now!",
      },
      title: {
        key: "tool.videotomp4.title",
        default: "Video to mp4",
      },
      subtitle: {
        key: "tool.videotomp4.subtitle",
        default: "Quickly convert your video files to MP4 now!",
      },
      tip: {
        title: {
          key: "tool.videotomp4.tipTitle",
          default: ["How to", "Video", "to Mp4"],
        },
        body: [
          {
            title: {
              key: "tool.videotomp4.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.videotomp4.tip1.text",
              default:
                "Add a video file by clicking on ‘Select Files’. Select your video or drag and drop it into the box.",
            },
          },
          {
            title: {
              key: "tool.videotomp4.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.videotomp4.tip2.text",
              default:
                "Select the format to be converted by clicking the drop-down menu in the middle of the screen, and click the 'convert' button to convert the format",
            },
          },
          {
            title: {
              key: "tool.videotomp4.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.videotomp4.tip3.text",
              default: "You can now download your video. Just click on ‘Export’ and you’re done!",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.videotomp4.successfulTitle",
        default: "Image to PDF Convert Successful",
      },
    },
  },
  //视频转avi
  {
    ID: 29,
    categoryID: 1,
    key: "2-4-avi",
    name: "VideoToAvi",
    path: "/VideoToAvi",
    domain: "videotoavi",
    functionType: "convert",
    icon: "avi.png",
    beforeToolIcon: "avi.png",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.png",
    color: "#7961F2",
    inputFileType: [
      "webm",
      "wmv",
      "3gp",
      "m4v",
      "mkv",
      "swf",
      "av1",
      "ts",
      "mts",
      "3gp",
      "3g2",
      "m2ts",
      "m2v",
      "mpeg",
      "mov",
      "mpg",
      "mpeg-2",
      "ogv",
      "flv",
      "mjpeg",
      "hevc",
      "vob",
      "mxf",
      "asf",
      "f4v",
      "rm",
      "wtv",
      "mp4",
    ],
    outputFileType: ["avi"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.videotoavi.name",
        default: "Video to avi",
      },
      description: {
        key: "tool.videotoavi.description",
        default: "Quickly convert your video files to mpeg now!",
      },
      title: {
        key: "tool.videotoavi.title",
        default: "Video to avi",
      },
      subtitle: {
        key: "tool.videotoavi.subtitle",
        default: "Quickly convert your video files to mpeg now!",
      },
      tip: {
        title: {
          key: "tool.videotoavi.tipTitle",
          default: ["How to", "Video", "to avi"],
        },
        body: [
          {
            title: {
              key: "tool.videotoavi.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.videotoavi.tip1.text",
              default:
                "Add a video file by clicking on ‘Select Files’. Select your video or drag and drop it into the box.",
            },
          },
          {
            title: {
              key: "tool.videotoavi.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.videotoavi.tip2.text",
              default:
                "Select the format to be converted by clicking the drop-down menu in the middle of the screen, and click the 'convert' button to convert the format.",
            },
          },
          {
            title: {
              key: "tool.videotoavi.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.videotoavi.tip3.text",
              default: "You can now download your video. Just click on ‘Export’ and you’re done!",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.videotoavi.successfulTitle",
        default: "Merge PDF Successful",
      },
    },
  },
  //视频转webm
  {
    ID: 30,
    categoryID: 1,
    key: "2-3-webm",
    name: "VideoToWebm",
    path: "/VideoToWebm",
    domain: "videotowebm",
    functionType: "convert",
    icon: "webm.png",
    beforeToolIcon: "webm.png",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.png",
    color: "#7961F2",
    inputFileType: [
      "avi",
      "wmv",
      "3gp",
      "m4v",
      "mkv",
      "swf",
      "av1",
      "ts",
      "mts",
      "3gp",
      "3g2",
      "m2ts",
      "m2v",
      "mpeg",
      "mov",
      "mpg",
      "mpeg-2",
      "ogv",
      "flv",
      "mjpeg",
      "hevc",
      "vob",
      "mxf",
      "asf",
      "f4v",
      "rm",
      "wtv",
      "mp4",
    ],
    outputFileType: ["webm"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.videotowebm.name",
        default: "Video to webm",
      },
      description: {
        key: "tool.videotowebm.description",
        default: "Quickly convert your video files to webm now!",
      },
      title: {
        key: "tool.videotowebm.title",
        default: "Video to webm",
      },
      subtitle: {
        key: "tool.videotowebm.subtitle",
        default: "Quickly convert your video files to webm now!",
      },
      tip: {
        title: {
          key: "tool.videotowebm.tipTitle",
          default: ["How to", "Video", "to webm"],
        },
        body: [
          {
            title: {
              key: "tool.videotowebm.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.videotowebm.tip1.text",
              default:
                "Add a video file by clicking on ‘Select Files’. Select your video or drag and drop it into the box.",
            },
          },
          {
            title: {
              key: "tool.videotowebm.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.videotowebm.tip2.text",
              default:
                "Select the format to be converted by clicking the drop-down menu in the middle of the screen, and click the 'convert' button to convert the format",
            },
          },
          {
            title: {
              key: "tool.videotowebm.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.videotowebm.tip3.text",
              default: "You can now download your video. Just click on ‘Export’ and you’re done!",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.videotowebm.successfulTitle",
        default: "Compress PDF Successful",
      },
    },
  },
  //视频转mpeg
  {
    ID: 31,
    categoryID: 1,
    key: "1-3-mpeg",
    name: "VideoToMpeg",
    path: "/VideoToMpeg",
    domain: "videotompeg",
    functionType: "convert",
    icon: "mpeg.png",
    beforeToolIcon: "mpeg.png",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.png",
    color: "#FFB700",
    inputFileType: [
      "avi",
      "webm",
      "wmv",
      "3gp",
      "m4v",
      "mkv",
      "swf",
      "av1",
      "ts",
      "mts",
      "3gp",
      "3g2",
      "m2ts",
      "m2v",
      "mov",
      "mpg",
      "mpeg-2",
      "ogv",
      "flv",
      "mjpeg",
      "hevc",
      "vob",
      "mxf",
      "asf",
      "f4v",
      "rm",
      "wtv",
      "mp4",
    ],
    outputFileType: ["mpg"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.videotompeg.name",
        default: "Video to mpeg",
      },
      description: {
        key: "tool.videotompeg.description",
        default: "Quickly convert your video files to mpeg now!",
      },
      title: {
        key: "tool.videotompeg.title",
        default: "Video to mpeg",
      },
      subtitle: {
        key: "tool.videotompeg.subtitle",
        default: "Quickly convert your video files to mpeg now!",
      },
      tip: {
        title: {
          key: "tool.videotompeg.tipTitle",
          default: ["How to", "Video", "to mpg"],
        },
        body: [
          {
            title: {
              key: "tool.videotompeg.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.videotompeg.tip1.text",
              default:
                "Add a video file by clicking on ‘Select Files’. Select your video or drag and drop it into the box.",
            },
          },
          {
            title: {
              key: "tool.videotompeg.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.videotompeg.tip2.text",
              default:
                "Select the format to be converted by clicking the drop-down menu in the middle of the screen, and click the 'convert' button to convert the format",
            },
          },
          {
            title: {
              key: "tool.videotompeg.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.videotompeg.tip3.text",
              default: "You can now download your video. Just click on ‘Export’ and you’re done!",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.videotompeg.successfulTitle",
        default: "Excel to PDF Convert Successful",
      },
    },
  },
  // 视频转wmv
  {
    ID: 32,
    categoryID: 1,
    key: "1-2-wmv",
    name: "VideoToWmv",
    path: "/VideoToWmv",
    domain: "videotowmv",
    functionType: "convert",
    icon: "wmv.png",
    beforeToolIcon: "wmv.png",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.png",
    color: "#FF8000",
    inputFileType: [
      "avi",
      "webm",
      "3gp",
      "m4v",
      "mkv",
      "swf",
      "av1",
      "ts",
      "mts",
      "3gp",
      "3g2",
      "m2ts",
      "m2v",
      "mpeg",
      "mov",
      "mpg",
      "mpeg-2",
      "ogv",
      "flv",
      "mjpeg",
      "hevc",
      "vob",
      "mxf",
      "asf",
      "f4v",
      "rm",
      "wtv",
      "mp4",
    ],
    outputFileType: ["wmv"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.videotowmv.name",
        default: "Video to wmv",
      },
      description: {
        key: "tool.videotowmv.description",
        default: "Quickly convert your video files to wmv now!",
      },
      title: {
        key: "tool.videotowmv.title",
        default: "Video to wmv",
      },
      subtitle: {
        key: "tool.videotowmv.subtitle",
        default: "Quickly convert your video files to wmv now!",
      },
      tip: {
        title: {
          key: "tool.videotowmv.tipTitle",
          default: ["How to", "Video", "to wmv"],
        },
        body: [
          {
            title: {
              key: "tool.videotowmv.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.videotowmv.tip1.text",
              default:
                "Add a video file by clicking on ‘Select Files’. Select your video or drag and drop it into the box.",
            },
          },
          {
            title: {
              key: "tool.videotowmv.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.videotowmv.tip2.text",
              default:
                "Select the format to be converted by clicking the drop-down menu in the middle of the screen, and click the 'convert' button to convert the format",
            },
          },
          {
            title: {
              key: "tool.videotowmv.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.videotowmv.tip3.text",
              default: "You can now download your video. Just click on ‘Export’ and you’re done!",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.videotowmv.successfulTitle",
        default: "PPT to PDF Convert Successful",
      },
    },
  },
  //视频转mov
  {
    ID: 33,
    categoryID: 1,
    key: "2-1-mov",
    name: "VideoToMov",
    path: "/VideoToMov",
    domain: "videotomov",
    functionType: "convert",
    icon: "mov.png",
    beforeToolIcon: "mov.png",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.png",
    color: "#0FC0C5",
    inputFileType: [
      "avi",
      "webm",
      "wmv",
      "3gp",
      "m4v",
      "mkv",
      "swf",
      "av1",
      "ts",
      "mts",
      "3gp",
      "3g2",
      "m2ts",
      "m2v",
      "mpeg",
      "mpg",
      "mpeg-2",
      "ogv",
      "flv",
      "mjpeg",
      "hevc",
      "vob",
      "mxf",
      "asf",
      "f4v",
      "rm",
      "wtv",
      "mp4",
    ],
    outputFileType: ["mov"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.videotomov.name",
        default: "Video to mov",
      },
      description: {
        key: "tool.videotomov.description",
        default: "Quickly convert your video files mov now!",
      },
      title: {
        key: "tool.videotomov.title",
        default: "Video to mov",
      },
      subtitle: {
        key: "tool.videotomov.subtitle",
        default: "Quickly convert your video files mov now!",
      },
      tip: {
        title: {
          key: "tool.videotomov.tipTitle",
          default: ["How to", "Video", "to mov"],
        },
        body: [
          {
            title: {
              key: "tool.videotomov.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.videotomov.tip1.text",
              default:
                "Add a video file by clicking on ‘Select Files’. Select your video or drag and drop it into the box.",
            },
          },
          {
            title: {
              key: "tool.videotomov.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.videotomov.tip2.text",
              default:
                "Select the format to be converted by clicking the drop-down menu in the middle of the screen, and click the 'convert' button to convert the format",
            },
          },
          {
            title: {
              key: "tool.videotomov.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.videotomov.tip3.text",
              default: "You can now download your video. Just click on ‘Export’ and you’re done!",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.videotomov.successfulTitle",
        default: "PDF Reader",
      },
    },
  },
  // 视频转mpg
  {
    ID: 34,
    categoryID: 1,
    key: "2-6-mpg",
    name: "VideoToMpg",
    path: "/VideoToMpg",
    domain: "videotompg",
    functionType: "convert",
    icon: "mpg.png",
    beforeToolIcon: "mpg.png",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.png",
    color: "#0FC0C5",
    inputFileType: [
      "avi",
      "webm",
      "wmv",
      "3gp",
      "m4v",
      "mkv",
      "swf",
      "av1",
      "ts",
      "mts",
      "3gp",
      "3g2",
      "m2ts",
      "m2v",
      "mpeg",
      "mov",
      "mpeg-2",
      "ogv",
      "flv",
      "mjpeg",
      "hevc",
      "vob",
      "mxf",
      "asf",
      "f4v",
      "rm",
      "wtv",
      "mp4",
    ],
    outputFileType: ["mpg"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.videotompg.name",
        default: "Video to mpg",
      },
      description: {
        key: "tool.videotompg.description",
        default: "Quickly convert your video files to mpg now!",
      },
      title: {
        key: "tool.videotompg.title",
        default: "Video to mpg",
      },
      subtitle: {
        key: "tool.videotompg.subtitle",
        default: "Quickly convert your video files to mpg now!",
      },
      tip: {
        title: {
          key: "tool.videotompg.tipTitle",
          default: ["How to", "Video", "to mpg"],
        },
        body: [
          {
            title: {
              key: "tool.videotompg.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.videotompg.tip1.text",
              default:
                "Add a video file by clicking on ‘Select Files’. Select your video or drag and drop it into the box.",
            },
          },
          {
            title: {
              key: "tool.videotompg.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.videotompg.tip2.text",
              default:
                "Select the format to be converted by clicking the drop-down menu in the middle of the screen, and click the 'convert' button to convert the format.",
            },
          },
          {
            title: {
              key: "tool.videotompg.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.videotompg.tip3.text",
              default: "You can now download your video. Just click on ‘Export’ and you’re done!",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.videotompg.successfulTitle",
        default: "Delete PDF Pages Successful",
      },
    },
  },
  //视频转3pg
  {
    ID: 35,
    categoryID: 1,
    key: "2-5-3gp",
    name: "VideoTo3gp",
    path: "/VideoTo3gp",
    domain: "videoto3gp",
    functionType: "convert",
    icon: "3gp.png",
    beforeToolIcon: "3gp.png",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.png",
    color: "#0FC0C5",
    inputFileType: [
      "avi",
      "webm",
      "wmv",
      "m4v",
      "mkv",
      "swf",
      "av1",
      "ts",
      "mts",
      "3gp",
      "3g2",
      "m2ts",
      "m2v",
      "mpeg",
      "mov",
      "mpg",
      "mpeg-2",
      "ogv",
      "flv",
      "mjpeg",
      "hevc",
      "vob",
      "mxf",
      "asf",
      "f4v",
      "rm",
      "wtv",
      "mp4",
    ],
    outputFileType: ["3pg"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.videoto3gp.name",
        default: "Video to 3gp",
      },
      description: {
        key: "tool.videoto3gp.description",
        default: "Quickly convert your video files to 3pg now!",
      },
      title: {
        key: "tool.videoto3gp.title",
        default: "Video to 3gp",
      },
      subtitle: {
        key: "tool.videoto3gp.subtitle",
        default: "Quickly convert your video files to 3pg now!",
      },
      tip: {
        title: {
          key: "tool.videoto3gp.tipTitle",
          default: ["How to", "Video", "to 3pg"],
        },
        body: [
          {
            title: {
              key: "tool.videoto3gp.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.videoto3gp.tip1.text",
              default:
                "Add a video file by clicking on ‘Select Files’. Select your video or drag and drop it into the box.",
            },
          },
          {
            title: {
              key: "tool.videoto3gp.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.videoto3gp.tip2.text",
              default:
                "Select the format to be converted by clicking the drop-down menu in the middle of the screen, and click the 'convert' button to convert the format",
            },
          },
          {
            title: {
              key: "tool.videoto3gp.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.videoto3gp.tip3.text",
              default: "You can now download your video. Just click on ‘Export’ and you’re done!",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.videoto3gp.successfulTitle",
        default: "Rotate PDF Successful",
      },
    },
  },
  //视频转mkv
  {
    ID: 36,
    categoryID: 1,
    key: "0-1-mkv",
    name: "VideoToMkv",
    path: "/VideoToMkv",
    domain: "videotomkv",
    functionType: "convert",
    icon: "mkv.png",
    beforeToolIcon: "mkv.png",
    arrow: "none",
    afterToolIcon: "icon_img.png",
    color: "#FFB700",
    inputFileType: [
      "avi",
      "webm",
      "wmv",
      "3gp",
      "m4v",
      "swf",
      "av1",
      "ts",
      "mts",
      "3gp",
      "3g2",
      "m2ts",
      "m2v",
      "mpeg",
      "mov",
      "mpg",
      "mpeg-2",
      "ogv",
      "flv",
      "mjpeg",
      "hevc",
      "vob",
      "mxf",
      "asf",
      "f4v",
      "rm",
      "wtv",
      "mp4",
    ],
    outputFileType: ["mkv"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.videotomkv.name",
        default: "Video to mkv",
      },
      description: {
        key: "tool.videotomkv.description",
        default: "Quickly convert your video files to mkv now!",
      },
      title: {
        key: "tool.videotomkv.title",
        default: "Video to mkv",
      },
      subtitle: {
        key: "tool.videotomkv.subtitle",
        default: "Quickly convert your video files to mkv now!",
      },
      tip: {
        title: {
          key: "tool.videotomkv.tipTitle",
          default: ["How to", "Video", "to mkv"],
        },
        body: [
          {
            title: {
              key: "tool.videotomkv.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.videotomkv.tip1.text",
              default:
                "Add a video file by clicking on ‘Select Files’. Select your video or drag and drop it into the box.",
            },
          },
          {
            title: {
              key: "tool.videotomkv.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.videotomkv.tip2.text",
              default:
                "Select the format to be converted by clicking the drop-down menu in the middle of the screen, and click the 'convert' button to convert the format.",
            },
          },
          {
            title: {
              key: "tool.videotomkv.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.videotomkv.tip3.text",
              default: "You can now download your video. Just click on ‘Export’ and you’re done!",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.videotomkv.successfulTitle",
        default: "PDF to JPG Convert Successful",
      },
    },
  },
  //视频转flv
  {
    ID: 37,
    categoryID: 1,
    key: "2-0-flv",
    name: "VideoToFlv",
    path: "/VideoToFlv",
    domain: "videotoflv",
    functionType: "convert",
    icon: "flv.png",
    beforeToolIcon: "flv.png",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.png",
    color: "#0FC0C5",
    inputFileType: [
      "avi",
      "webm",
      "wmv",
      "3gp",
      "m4v",
      "mkv",
      "swf",
      "av1",
      "ts",
      "mts",
      "3gp",
      "3g2",
      "m2ts",
      "m2v",
      "mpeg",
      "mov",
      "mpg",
      "mpeg-2",
      "ogv",
      "mjpeg",
      "hevc",
      "vob",
      "mxf",
      "asf",
      "f4v",
      "rm",
      "wtv",
      "mp4",
    ],
    outputFileType: ["flv"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.videotoflv.name",
        default: "Video to flv",
      },
      description: {
        key: "tool.videotoflv.description",
        default: "Quickly convert your video files to flv now!",
      },
      title: {
        key: "tool.videotoflv.title",
        default: "Video to flv",
      },
      subtitle: {
        key: "tool.videotoflv.subtitle",
        default: "Quickly convert your video files to flv now!",
      },
      tip: {
        title: {
          key: "tool.videotoflv.tipTitle",
          default: ["How to", "Video", "to flv"],
        },
        body: [
          {
            title: {
              key: "tool.videotoflv.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.videotoflv.tip1.text",
              default:
                "Add a video file by clicking on ‘Select Files’. Select your video or drag and drop it into the box.",
            },
          },
          {
            title: {
              key: "tool.videotoflv.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.videotoflv.tip2.text",
              default:
                "Select the format to be converted by clicking the drop-down menu in the middle of the screen, and click the 'convert' button to convert the format.",
            },
          },
          {
            title: {
              key: "tool.videotoflv.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.videotoflv.tip3.text",
              default: "You can now download your video. Just click on ‘Export’ and you’re done!",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.videotoflv.successfulTitle",
        default: "PDF Edit Successful",
      },
    },
  },
  //视频转vob
  {
    ID: 38,
    categoryID: 1,
    key: "2-0-vob",
    name: "VideoToVob",
    path: "/VideoToVob",
    domain: "videotovob",
    functionType: "convert",
    icon: "vob.png",
    beforeToolIcon: "vob.png",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.png",
    color: "#0FC0C5",
    inputFileType: [
      "avi",
      "webm",
      "wmv",
      "3gp",
      "m4v",
      "mkv",
      "swf",
      "av1",
      "ts",
      "mts",
      "3gp",
      "3g2",
      "m2ts",
      "m2v",
      "mpeg",
      "mov",
      "mpg",
      "mpeg-2",
      "ogv",
      "flv",
      "mjpeg",
      "hevc",
      "mxf",
      "asf",
      "f4v",
      "rm",
      "wtv",
      "mp4",
    ],
    outputFileType: ["vob"],
    recommendFunction: ["more", 3, 9, 15, 32, 11, 30, 31],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.videotovob.name",
        default: "Video to vob",
      },
      description: {
        key: "tool.videotovob.description",
        default: "Quickly convert your video files to vob now!",
      },
      title: {
        key: "tool.videotovob.title",
        default: "Video to vob",
      },
      subtitle: {
        key: "tool.videotovob.subtitle",
        default: "Quickly convert your video files to vob now!",
      },
      tip: {
        title: {
          key: "tool.videotovob.tipTitle",
          default: ["How to", "Video", "to vob"],
        },
        body: [
          {
            title: {
              key: "tool.videotovob.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.videotovob.tip1.text",
              default:
                "Add a video file by clicking on ‘Select Files’. Select your video or drag and drop it into the box.",
            },
          },
          {
            title: {
              key: "tool.videotovob.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.videotovob.tip2.text",
              default:
                "Select the format to be converted by clicking the drop-down menu in the middle of the screen, and click the 'convert' button to convert the format.",
            },
          },
          {
            title: {
              key: "tool.videotovob.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.videotovob.tip3.text",
              default: "You can now download your video. Just click on ‘Export’ and you’re done!",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.videotovob.successfulTitle",
        default: "PDF Edit Successful",
      },
    },
  },
];
export const extraTool = [
  {
    key: "ex-0-moretool",
    name: "MoreTool",
    path: "/",
    domain: "",
    icon: "icon_more_tool.svg",
    context: {
      name: {
        key: "tool.moretool.name",
        default: "More tools",
      },
    },
  },
];
// 模板列表
// export const templates = [
//   {
//     toolName: "Legal Contract",
//     path: "/LegalContract",
//     EventCategroy: "LegalContract",
//     domain: "templates",
//     isNew: false,
//     isImage: false,
//     selectDescribe: "Upload steps: Just click on the PDF or drag and drop to upload and then ",
//     beforeToolIcon: "LegalContract.png",
//     fill: "#FC9A9A",
//   },
//   {
//     toolName: "Chart Vector",
//     path: "/ChartVector",
//     EventCategroy: "ChartVector",
//     domain: "templates",
//     isNew: false,
//     isImage: false,
//     selectDescribe: "Upload steps: Just click on the PDF or drag and drop to upload and then ",
//     beforeToolIcon: "ChartVector.svg",
//     fill: "#FFCB00",
//   },
//   {
//     toolName: "PDF/A Document",
//     path: "/PDFADocument",
//     EventCategroy: "PDFADocument",
//     domain: "templates",
//     isNew: false,
//     isImage: false,
//     selectDescribe: "Upload steps: Just click on the PDF or drag and drop to upload and then ",
//     beforeToolIcon: "PDFADocument.svg",
//     fill: "#82D8FF",
//   },
//   {
//     toolName: "PDF/A Document",
//     path: "/PDFBDocument",
//     EventCategroy: "PDFBDocument",
//     domain: "templates",
//     isNew: false,
//     isImage: false,
//     selectDescribe: "Upload steps: Just click on the PDF or drag and drop to upload and then ",
//     beforeToolIcon: "PDFBDocument.svg",
//     fill: "#A25DDC",
//   },
// ];
// templates
export default { toolCategory, toolList, extraTool };
