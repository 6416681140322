// VideoToolList
// 引入功能模块
// REACT
import React from "react";
// MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import getAllUrlParams from "../../utils/getAllUrlParams";
import { GlobalContext } from "../../utils/globalContext";
// DATA
// PDF工具列表数据包
import { allToolList } from "../../data";
import { Trans } from "react-i18next/icu.macro";
import Link from "./Link";

//工具模块组件
function ToolCardNew({ tool }) {
  // 获取错误页面信息
  const { getLastReferrer } = React.useContext(GlobalContext);
  let [isHome, setIsHome] = React.useState(true);
  React.useEffect(async () => {
    const currentPageUrl = await getLastReferrer();
    if (currentPageUrl.currentPage !== "/") {
      setIsHome(false);
    } else {
      setIsHome(true);
    }
  }, []);
  return (
    <Grid
      item
      container
      xs={11.7}
      sm={6}
      md={4}
      xl={3}
      sx={{
        "&>a": {
          textDecorationLine: "none",
          width: "100%",
        },
        // display:
        //   /Mobi|Android|iPhone/i.test(navigator.userAgent) === true && tool.ID === 12
        //     ? "none"
        //     : "block",
      }}
    >
      <Link href={tool.path} subDomain={tool.domain} position="function_tool_list">
        {/* 工具卡片 */}
        <Card
          sx={{
            height: "226px",

            border: "2px solid rgba(238, 238, 238,0.4)",
            maxHeight: "252px",
            width: "100%",
            padding: "0px",
            maxWidth: "500px",
            margin: {
              xl: "10px 20px 10px 20px",
              md: "5px 10px",
              sm: "4px 8px",
              xs: "2px 5px",
            },
            boxShadow: "0px 0.2px 10px  rgba(0, 0, 0, 0.03)",
            "&:hover": { border: "2px solid rgb(50, 145, 255)" },
          }}
          onClick={() => {
            sendConstructEventByEventParams("open_function", {});
            isPC && sendConstructEventByEventParams("open_function_pc", {});
            isHome &&
              sendConstructEventByEventParams("home", {
                click_tools: tool.name,
              });
            !isHome &&
              sendConstructEventByEventParams("select_file_page", {
                click_tools: tool.name,
              });
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* 工具图标 */}
            {tool.arrow !== "none" ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "& img": {
                    marginRight: "10px",
                  },
                  marginBottom: {
                    xl: "20px",
                    md: "20px",
                    sm: "20px",
                    xs: "10px",
                  },
                }}
              >
                {/* <div>123</div> */}
                <img width="48px" src={"/images/icon/" + tool.beforeToolIcon} />
                <img src={"/images/icon/" + tool.arrow} />
                <img width="48px" src={"/images/icon/" + tool.afterToolIcon} />
              </Box>
            ) : (
              <Box
                sx={{
                  width: { xs: "36px", sm: "48px", md: "48px", xl: "48px" },
                  display: "flex",
                  marginBottom: {
                    xl: "20px",
                    md: "20px",
                    sm: "20px",
                    xs: "10px",
                  },
                }}
              >
                {/* <div>123123</div> */}
                <img width="48px" src={"/images/icon/" + tool.beforeToolIcon} />
              </Box>
            )}

            <Box>
              {/* 工具名称 */}
              <Typography
                // variant="body2"
                sx={{
                  textAlign: "center",
                  fontSize: { xl: "24px", md: "20px", sm: "18px", xs: "16px" },
                  fontWeight: "700",
                  paddingLeft: "10px",
                  marginBottom: { xl: "20px", md: "20px", sm: "10px", xs: "0" },
                }}
              >
                <Trans i18nKey={tool.context.name.default}>{tool.context.name.default}</Trans>
              </Typography>
              {/* 工具描述 */}
              <Typography
                // variant="body5"
                sx={{
                  textAlign: "center",
                  fontSize: { xl: "18px", md: "16px", sm: "14px", xs: "14px" },
                  overflow: "hidden",
                  height: "3rem",
                  lineHeight: "1.24rem",
                  textOverflow: "ellipsis",
                  boxSizing: "content-box",
                  display: "-webkit-box",
                  /* autoprefixer: off */
                  WebkitBoxOrient: "vertical",
                  /* autoprefixer: on */
                  WebkitLineClamp: "3",
                  padding: { xl: "13px", md: "10px", sm: "10px", xs: "6px" },
                }}
              >
                <Trans i18nKey={tool.context.description.default}>
                  {tool.context.description.default}
                </Trans>
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Link>
    </Grid>
  );
}

// MAIN
export default function VideoToolList() {
  // 模板列表,暂时没有模板
  // const templatesToolList = TemplatesToolList();
  let [homeToolList, setHomeToolList] = React.useState([]);
  React.useEffect(() => {
    if (getAllUrlParams(window.location.href).toolListSort !== undefined) {
      let toolListSort = getAllUrlParams(window.location.href).toolListSort;
      // 现对获取的参数进行判断
      toolListSort = decodeURI(toolListSort);
      //  将 中括号里面的内容 拿出来
      toolListSort = toolListSort.slice(toolListSort.indexOf("[") + 1, toolListSort.indexOf("]"));
      toolListSort = toolListSort.split(",");
      toolListSort.forEach((item, index) => {
        if (item > 12 || item < 0) {
          log("请输入0~12");
        } else {
          homeToolList[index] = allToolList[item];
          setHomeToolList(homeToolList);
        }
      });
    } else {
      setHomeToolList(allToolList);
    }
  }, []);
  return (
    <Box sx={{ position: "relative" }}>
      {/* 标题 */}

      {/* 工具列表 */}

      {/* 工具列表 */}
      <Box
        sx={{
          flexGrow: 1,
          margin: "auto",
          maxWidth: "1200px",
          marginTop: "20px",
        }}
      >
        <Grid container spacing={1}>
          {homeToolList.map((tool) => {
            return <ToolCardNew key={tool.key} tool={tool} />;
          })}
        </Grid>
      </Box>
    </Box>
  );
}
