import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation, Translation } from "react-i18next";

require("typeface-quicksand");
// 创建自定义主题
const theme = createTheme({
  // 调色板
  palette: {
    // 默认主题颜色(按钮)
    primary: {
      main: "#3291ff",
    },
    // 次要主题颜色(输入框)
    secondary: {
      main: "#000",
    },
  },
  // 媒体查询断点
  breakpoints: {
    // 断点值(屏幕宽度)
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  // 文字铸排
  typography: {
    // 默认字体
    fontFamily: ["myFont"].join(","),
    // 文字变体(variant)
    // 全局默认字体大小16px(1rem=16px)
    // 标题
    h1: {
      // 默认字体大小
      fontSize: "5.125rem", //82px
      // 默认行高
      lineHeight: "6.4375rem",
      // 默认字重
      fontWeight: 700,
      // 媒体查询屏幕宽度大于600px时
      // "@media (min-width:600px)": {
      //   fontSize: "1.5rem",
      // },
      // 媒体查询屏幕宽度小于600px时
      // "@media (max-width:600px)": {
      //   fontSize: "1.5rem",
      // },
    },
    h2: { fontSize: "3.125rem", lineHeight: "3.9375rem", fontWeight: 700 }, //50px
    h3: {
      fontSize: "3rem",
      lineHeight: "3.75rem",
      fontWeight: 700,
      "@media (max-width:600px)": {
        fontSize: "2rem",
      },
    }, //48px
    h4: { fontSize: "2.875rem", lineHeight: "3.625rem", fontWeight: 700 }, //46px
    h5: { fontSize: "2.625rem", lineHeight: "3.3125rem", fontWeight: 500 }, //42px
    h6: { fontSize: "2rem", lineHeight: "2.875rem", fontWeight: 500 }, //32px
    // 副标题
    subtitle1: { fontSize: "2.5rem", lineHeight: "3.125rem", fontWeight: 700 }, //40px
    subtitle2: {
      fontSize: "2rem",
      lineHeight: "2.5rem",
      fontWeight: 700,
      "@media (max-width:600px)": {
        fontSize: "1.5rem",
      },
    }, //32px
    // 按钮
    button: {
      fontSize: "1.5rem",
      lineHeight: "1.875rem",
      fontWeight: 700,
      "@media (max-width:600px)": {
        fontSize: "1.25rem",
      },
    }, //24px
    // 文本
    body1: {
      fontSize: "1.5rem",
      lineHeight: "1.875rem",
      fontWeight: 500,
      "@media (max-width:600px)": {
        fontSize: "1.25rem",
      },
    }, //24px
    body2: {
      fontSize: "1.375rem", lineHeight: "1.75rem",
      fontWeight: 500,
      "@media (max-width:600px)": {
        fontSize: "0.9rem",
      },
    }, //22px
    body3: {
      fontSize: "1.25rem",
      lineHeight: "1.5625rem",
      fontWeight: 500,
      fontFamily: "myFont",
    }, //20px
    body4: {
      fontSize: "1.125rem",
      lineHeight: "1.4375rem",
      fontWeight: 500,
      fontFamily: "myFont",
    }, //18px
    body5: {
      fontSize: "1rem",
      lineHeight: "1.25rem",
      fontWeight: 500,
      fontFamily: "myFont",

    }, //16px
    videoSpeed: {
      fontSize: "1.125rem",
      lineHeight: "1.5rem",
      fontWeight: 400,
      fontFamily: "myFont",
    },
    editorTitle: {
      fontSize: "1.125rem",
      lineHeight: "1.5rem",
      fontWeight: 600,
      fontFamily: "myFont",
    }, //18px 600
    editorTool: {
      fontSize: "0.8125rem",
      lineHeight: "1rem",
      fontWeight: 500,
      fontFamily: "myFont",
    }, //13px 500
    editorText: {
      fontSize: "0.71875rem",
      lineHeight: "0.8125rem",
      fontWeight: 400,
      fontFamily: "myFont",
    }, //12px 400
    caption: { fontSize: "0.875rem", lineHeight: "0.875rem", fontWeight: 400 }, //14px
    //编辑区
    editorMenuItem: {
      fontSize: "0.875rem",
      lineHeight: "0.875rem",
      fontWeight: 600,
      fontFamily: "Inter",
    },
  },
  // 组件样式
  components: {
    // 组件名称
    // MUI 输入框
    MuiInput: {
      // 组件样式
      styleOverrides: {
        // 插槽名称
        // 默认插槽
        root: {
          fontSize: "1rem",
          "@media (max-width:600px)": {
            fontSize: "0.9rem",
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          top: "50px !important",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
        },
      },
    },
    // MUI Label
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "0.875rem",
          lineHeight: "1.125rem",
        },
      },
    },
    // MUI 按钮
    MuiButton: {
      styleOverrides: {
        root: {
          whiteSpace: "nowrap",
          textTransform: "none",
          borderRadius: "4px!important",
          "@media (max-width:600px)": {
            fontSize: "1rem",
          },
        },
      },
    },
    // // MuiMenuItem下拉框
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "@media (max-width:600px)": {
            fontSize: "0.8rem",
          },
        },
      },
    },
    // // MuiSelect下拉框
    MuiSelect: {
      styleOverrides: {
        root: {
          "@media (max-width:600px)": {
            fontSize: "0.8rem",
          },
        },
      },
    },
    // // MuiToggleButton按钮
    MuiToggleButton: {
      styleOverrides: {
        root: {
          "@media (max-width:600px)": {
            fontSize: "1rem",
          },
        },
      },
    },

    // MUI 图标按钮
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    // MUI 容器
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: "1440px",
        },
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          "& .css-1mm9f1u-MuiLoadingButton-loadingIndicator": {
            color: "red",
          },

        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "16px",
        },
      },
    },
  },
});

export default function GlobalTheme(props) {
  // 发布自定义主题
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}
