// 顶部导航
// 引入功能模块
// REACT
import React from "react";
// MUI
import { Box, Drawer } from "@mui/material";
// MUI ICON
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import Loading from "../../toolPage/component/Loading";

// COMPONENT
// 登录页面
const Login = React.lazy(() => import("./Login"));
// 重置密码页面

const ResetPasswordByForget = React.lazy(() =>
  import("./ResetPasswordByForget")
);
// 注册页面
const OnTrial = React.lazy(() => import("./OnTrial"));
import { useMedia } from "react-use";
// MAIN
export default function MyDrawer(props) {
  // 抽屉页面弹出方向
  const [drawerPopupAnchor, setDrawerPopupAnchor] = React.useState(
    props.anchor
  );
  // 抽屉页面弹出状态,当某一方向属性为true时,抽屉从该方向弹出
  const [drawerPopupState, setDrawerPopupState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  // 抽屉当前展示的页面
  const [nowPage, setNowPage] = React.useState(props.startPage);
  // 抽屉展示的全部页面
  const pages = ["login", "reset", "onTrial"];
  // 抽屉开关 drawerPopupAnchor抽屉页面弹出方向 toggle开关
  const toggleDrawer = (drawerPopupAnchor, toggle) => (event) => {
    // 如果用户按住"Tab"或"Shift",则不响应关闭事件
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    //关闭的时候，还原默认默认设置
    if (!toggle) {
      setNowPage(props.startPage);
    }
    setDrawerPopupState({ ...drawerPopupState, [drawerPopupAnchor]: toggle });
  };
  // 抽屉页面
  function DrawerPage({ drawerPopupAnchor }) {
    return (
      <Box
        sx={{
          position: "relative",
          width:
            drawerPopupAnchor === "top" || drawerPopupAnchor === "bottom"
              ? "auto"
              : "100vw",
        }}
      >
        <CloseSharpIcon
          sx={{
            color: "rgb(0,0,0)",
            position: "absolute",
            top: "30px",
            right: "30px",
          }}
          onClick={toggleDrawer(drawerPopupAnchor, false)}
        ></CloseSharpIcon>
        {nowPage === pages[0] && (
          <React.Suspense fallback={<Loading />}>
            <Login
              setNowPage={setNowPage}
              pages={pages}
              setDrawerPopupState={setDrawerPopupState}
            />
          </React.Suspense>
        )}
        {nowPage === pages[1] && (
          <React.Suspense fallback={<Loading />}>
            <ResetPasswordByForget setNowPage={setNowPage} pages={pages} />
          </React.Suspense>
        )}
        {nowPage === pages[2] && (
          <React.Suspense fallback={<Loading />}>
            <OnTrial from={props.from} setNowPage={setNowPage} pages={pages} />
          </React.Suspense>
        )}
      </Box>
    );
  }

  // 抽屉页面
  function DrawerPageMedia({ drawerPopupAnchor }) {
    return (
      <Box
        sx={{
          width:
            drawerPopupAnchor === "top" || drawerPopupAnchor === "bottom"
              ? "auto"
              : "100vw",
        }}
      >
        <CloseSharpIcon
          sx={{
            color: "rgb(0,0,0)",
            fontSize: "30px",
            float: "right",
            margin: "30px",
          }}
          onClick={toggleDrawer(drawerPopupAnchor, false)}
        ></CloseSharpIcon>
        {nowPage === pages[0] && (
          <React.Suspense fallback={<Loading />}>
            <Login
              setNowPage={setNowPage}
              pages={pages}
              setDrawerPopupState={setDrawerPopupState}
            />
          </React.Suspense>
        )}
        {nowPage === pages[1] && (
          <React.Suspense fallback={<Loading />}>
            <ResetPasswordByForget setNowPage={setNowPage} pages={pages} />
          </React.Suspense>
        )}
        {nowPage === pages[2] && (
          <React.Suspense fallback={<Loading />}>
            <OnTrial from={props.from} setNowPage={setNowPage} pages={pages} />
          </React.Suspense>
        )}
      </Box>
    );
  }
  //媒体查询
  const isWide = useMedia("(max-width: 600px)");
  if (isWide) {
    return (
      <React.Fragment>
        <Box onClick={toggleDrawer(drawerPopupAnchor, true)}>
          {props.children}
        </Box>
        <Drawer
          anchor={drawerPopupAnchor}
          open={drawerPopupState[drawerPopupAnchor]}
          onClose={toggleDrawer(drawerPopupAnchor, false)}
        >
          <DrawerPageMedia drawerPopupAnchor={drawerPopupAnchor} />
        </Drawer>
      </React.Fragment>
    );
  } else
    return (
      <React.Fragment>
        <Box onClick={toggleDrawer(drawerPopupAnchor, true)}>
          {props.children}
        </Box>
        <Drawer
          anchor={drawerPopupAnchor}
          open={drawerPopupState[drawerPopupAnchor]}
          onClose={toggleDrawer(drawerPopupAnchor, false)}
        >
          <DrawerPage drawerPopupAnchor={drawerPopupAnchor} />
        </Drawer>
      </React.Fragment>
    );
}
