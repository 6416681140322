// 全局路由管理文件
// REACT
import React, { useContext } from "react";
import { GlobalContext } from "../utils/globalContext";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
// COMPONENT
import Home from "../page/Home";
import Loading from "../page/toolPage/component/Loading";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const VideoEditor = React.lazy(() => import("../page/toolPage/VideoEditor"));
const AddSubtitles = React.lazy(() => import("../page/toolPage/AddSubtitles"));
const AddSubtitlesWorkspace = React.lazy(() =>
  import("../page/toolPage/AddSubtitles/AddSubtitleWorkspace"),
);
const AddAudioToVideo = React.lazy(() => import("../page/toolPage/AddAudioToVideo"));
const AddPhotoToVideo = React.lazy(() => import("../page/toolPage/AddPhotoToVideo"));
const AddStickerToVideo = React.lazy(() => import("../page/toolPage/AddStickerToVideo"));
const AddTextToVideo = React.lazy(() => import("../page/toolPage/AddTextToVideo"));
const VideoToText = React.lazy(() => import("../page/toolPage/VideoToText"));
const CropVideo = React.lazy(() => import("../page/toolPage/CropVideo"));
const CropWorkSpace = React.lazy(() => import("../page/toolPage/CropVideo/CropWorkSpace"));
const FlipVideo = React.lazy(() => import("../page/toolPage/FlipVideo"));
const ResizeVideo = React.lazy(() => import("../page/toolPage/ResizeVideo"));
const ResizeWorkSpace = React.lazy(() => import("../page/toolPage/ResizeVideo/ResizeWorkSpace"));
const RemoveBackgroundNoise = React.lazy(() => import("../page/toolPage/RemoveBackgroundNoise"));
const RotateVideo = React.lazy(() => import("../page/toolPage/RotateVideo"));
const VideoCompress = React.lazy(() => import("../page/toolPage/VideoCompress"));
const VideoCutter = React.lazy(() => import("../page/toolPage/VideoCutter"));
const CutterWorkSpace = React.lazy(() => import("../page/toolPage/VideoCutter/CutterWorkSpace"));
const VideoEditorEffects = React.lazy(() => import("../page/toolPage/VideoEditorEffects"));
const VideoEditorTransition = React.lazy(() => import("../page/toolPage/VideoEditorTransition"));
const VideoMerge = React.lazy(() => import("../page/toolPage/VideoMerge"));
const VideoMergeWorkspace = React.lazy(() =>
  import("../page/toolPage/VideoMerge/VideoMergeWorkspace"),
);
const VideoFilters = React.lazy(() => import("../page/toolPage/VideoFilters"));
const VideoFrameRateConverter = React.lazy(() =>
  import("../page/toolPage/VideoFrameRateConverter"),
);
const VideoOverlay = React.lazy(() => import("../page/toolPage/VideoOverlay"));
const VideoSpliter = React.lazy(() => import("../page/toolPage/VideoSpliter"));
const MuteVideo = React.lazy(() => import("../page/toolPage/MuteVideo"));
const VideoSpeedController = React.lazy(() => import("../page/toolPage/VideoSpeedController"));
const VideoToAudio = React.lazy(() => import("../page/toolPage/VideoToAudio"));
const VideoToGif = React.lazy(() => import("../page/toolPage/VideoToGif"));
const ReverseVideo = React.lazy(() => import("../page/toolPage/ReverseVideo"));
const GIFToVideo = React.lazy(() => import("../page/toolPage/GIFToVideo"));
const VideoTranslator = React.lazy(() => import("../page/toolPage/VideoTranslator"));
const VideoToMp4 = React.lazy(() => import("../page/toolPage/VideoToMp4"));
const VideoToAvi = React.lazy(() => import("../page/toolPage/VideoToAvi"));
const VideoToWebm = React.lazy(() => import("../page/toolPage/VideoToWebm"));
const VideoToMpeg = React.lazy(() => import("../page/toolPage/VideoToMpeg"));
const VideoToWmv = React.lazy(() => import("../page/toolPage/VideoToWmv"));
const VideoToMov = React.lazy(() => import("../page/toolPage/VideoToMov"));
const VideoToMpg = React.lazy(() => import("../page/toolPage/VideoToMpg"));
const VideoTo3gp = React.lazy(() => import("../page/toolPage/VideoTo3gp"));
const VideoToMkv = React.lazy(() => import("../page/toolPage/VideoToMkv"));
const VideoToFlv = React.lazy(() => import("../page/toolPage/VideoToFlv"));
const VideoToVob = React.lazy(() => import("../page/toolPage/VideoToVob"));
const AutoCutTemplate = React.lazy(() => import("../page/toolPage/AutoCutTemplate"));
const AutoCutPreview = React.lazy(() => import("../page/toolPage/AutoCutPreview"));
const AutoCutChoose = React.lazy(() => import("../page/toolPage/AutoCutChoose"));
const UploadAndGet = React.lazy(() => import("../page/toolPage/AutoCutChoose/UploadAndGet"));
const PreviewResult = React.lazy(() => import("../page/toolPage/AutoCutChoose/PreviewResult"));
//导入
const ForgetPassWeb = React.lazy(() => import("../page/component/User/ForgetPassWeb"));
const Result = React.lazy(() => import("../page/Result"));
const VideoEditorWorkSpace = React.lazy(() =>
  import("../page/toolPage/VideoEditorWorkSpace/VideoEditorWorkSpace"),
);
const Profile = React.lazy(() => import("../page/Profile/Profile"));
const ProfileContent = React.lazy(() => import("../page/Profile/ProfileContent"));
const ResetPasswordByOldPassword = React.lazy(() =>
  import("../page/Profile/ResetPasswordByOldPassword"),
);
const SubscribePage = React.lazy(() => import("../page/subscribe/SubscribePage"));
const Support = React.lazy(() => import("../page/Support"));
const MiddlePageOfConvert = React.lazy(() => import("../page/toolPage/MiddlePageOfConvert"));
const VideoSpeedControllerWorkspace = React.lazy(() =>
  import("../page/toolPage/VideoSpeedController/VideoSpeedControllerWorkspace"),
);
const VideoCollageWorkspace = React.lazy(() =>
  import("../page/toolPage/VideoCollage/VideoCollageWorkspace"),
);

// video convert iframe
const VideoConvertIframe = React.lazy(() => import("../page/toolPage/VideoConvertIframe"));

export default function Router() {
  let { isOpenSnackbar, setIsOpenSnackbar, isLoading, setIsLoading } = useContext(GlobalContext);
  function handleClose() {
    setIsOpenSnackbar(false);
  }
  return (
    <BrowserRouter>
      {/* 路由模式 */}
      <Routes history={history}>
        {/* 主页 */}
        <Route path="/" element={<Home />} />
        <Route path=":tag/" element={<Home />} />
        <Route path=":tag1/:tag2/" element={<Home />} />
        <Route
          path="/videoConvertIframe"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoConvertIframe />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/videoConvertIframe"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoConvertIframe />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/videoConvertIframe"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoConvertIframe />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/Convert"
          element={
            <React.Suspense fallback={<Loading />}>
              <MiddlePageOfConvert />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/Convert"
          element={
            <React.Suspense fallback={<Loading />}>
              <MiddlePageOfConvert />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/:tag3/Convert"
          element={
            <React.Suspense fallback={<Loading />}>
              <MiddlePageOfConvert />
            </React.Suspense>
          }
        />
        <Route
          path="/VideoEditorWorkSpace"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoEditorWorkSpace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/VideoEditorWorkSpace"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoEditorWorkSpace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/:tag3/VideoEditorWorkSpace"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoEditorWorkSpace />
            </React.Suspense>
          }
        />

        {/* //视频编辑页面 */}
        <Route
          path="/VideoEditor"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoEditor />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/VideoEditor"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoEditor />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/VideoEditor"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoEditor />
            </React.Suspense>
          }
        />
        {/* //添加字幕页面页面 */}
        <Route
          path="/AddSubtitles"
          element={
            <React.Suspense fallback={<Loading />}>
              <AddSubtitles />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/AddSubtitles"
          element={
            <React.Suspense fallback={<Loading />}>
              <AddSubtitles />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/AddSubtitles"
          element={
            <React.Suspense fallback={<Loading />}>
              <AddSubtitles />
            </React.Suspense>
          }
        />
        {/* WorkSpace */}
        <Route
          path="/AddSubtitles/AddSubtitlesWorkspace"
          element={
            <React.Suspense fallback={<Loading />}>
              <AddSubtitlesWorkspace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/AddSubtitles/AddSubtitlesWorkspace"
          element={
            <React.Suspense fallback={<Loading />}>
              <AddSubtitlesWorkspace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/AddSubtitles/AddSubtitlesWorkspace"
          element={
            <React.Suspense fallback={<Loading />}>
              <AddSubtitlesWorkspace />
            </React.Suspense>
          }
        />
        {/* //将音频添加到视频 */}
        <Route
          path="/AddAudioToVideo"
          element={
            <React.Suspense fallback={<Loading />}>
              <AddAudioToVideo />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/AddAudioToVideo"
          element={
            <React.Suspense fallback={<Loading />}>
              <AddAudioToVideo />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/AddAudioToVideo"
          element={
            <React.Suspense fallback={<Loading />}>
              <AddAudioToVideo />
            </React.Suspense>
          }
        />
        {/* //将照片添加到视频 */}
        <Route
          path="/AddPhotoToVideo"
          element={
            <React.Suspense fallback={<Loading />}>
              <AddPhotoToVideo />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/AddPhotoToVideo"
          element={
            <React.Suspense fallback={<Loading />}>
              <AddPhotoToVideo />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/AddPhotoToVideo"
          element={
            <React.Suspense fallback={<Loading />}>
              <AddPhotoToVideo />
            </React.Suspense>
          }
        />
        {/* //将贴纸添加到视频 */}
        <Route
          path="/AddStickerToVideo"
          element={
            <React.Suspense fallback={<Loading />}>
              <AddStickerToVideo />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/AddStickerToVideo"
          element={
            <React.Suspense fallback={<Loading />}>
              <AddStickerToVideo />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/AddStickerToVideo"
          element={
            <React.Suspense fallback={<Loading />}>
              <AddStickerToVideo />
            </React.Suspense>
          }
        />
        {/* //将文本添加到视频 */}
        <Route
          path="/AddTextToVideo"
          element={
            <React.Suspense fallback={<Loading />}>
              <AddTextToVideo />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/AddTextToVideo"
          element={
            <React.Suspense fallback={<Loading />}>
              <AddTextToVideo />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/AddTextToVideo"
          element={
            <React.Suspense fallback={<Loading />}>
              <AddTextToVideo />
            </React.Suspense>
          }
        />
        {/* //视频转化成文本 */}
        <Route
          path="/VideoToText"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToText />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/VideoToText"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToText />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/VideoToText"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToText />
            </React.Suspense>
          }
        />
        {/* //裁剪视频 */}
        <Route
          path="/CropVideo"
          element={
            <React.Suspense fallback={<Loading />}>
              <CropVideo />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/CropVideo"
          element={
            <React.Suspense fallback={<Loading />}>
              <CropVideo />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/CropVideo"
          element={
            <React.Suspense fallback={<Loading />}>
              <CropVideo />
            </React.Suspense>
          }
        />

        <Route
          path="/CropVideo/CropWorkSpace"
          element={
            <React.Suspense fallback={<Loading />}>
              <CropWorkSpace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/CropVideo/CropWorkSpace"
          element={
            <React.Suspense fallback={<Loading />}>
              <CropWorkSpace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/CropVideo/CropWorkSpace"
          element={
            <React.Suspense fallback={<Loading />}>
              <CropWorkSpace />
            </React.Suspense>
          }
        />
        {/* //翻转视频 */}
        <Route
          path="/FlipVideo"
          element={
            <React.Suspense fallback={<Loading />}>
              <FlipVideo />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/FlipVideo"
          element={
            <React.Suspense fallback={<Loading />}>
              <FlipVideo />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/FlipVideo"
          element={
            <React.Suspense fallback={<Loading />}>
              <FlipVideo />
            </React.Suspense>
          }
        />
        {/* //缩放视频 */}
        <Route
          path="/ResizeVideo"
          element={
            <React.Suspense fallback={<Loading />}>
              <ResizeVideo />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/ResizeVideo"
          element={
            <React.Suspense fallback={<Loading />}>
              <ResizeVideo />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/ResizeVideo"
          element={
            <React.Suspense fallback={<Loading />}>
              <ResizeVideo />
            </React.Suspense>
          }
        />
        <Route
          path="/ResizeVideo/ResizeWorkSpace"
          element={
            <React.Suspense fallback={<Loading />}>
              <ResizeWorkSpace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/ResizeVideo/ResizeWorkSpace"
          element={
            <React.Suspense fallback={<Loading />}>
              <ResizeWorkSpace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/ResizeVideo/ResizeWorkSpace"
          element={
            <React.Suspense fallback={<Loading />}>
              <ResizeWorkSpace />
            </React.Suspense>
          }
        />

        {/* //移除背景噪音 */}
        <Route
          path="/RemoveBackgroundNoise"
          element={
            <React.Suspense fallback={<Loading />}>
              <RemoveBackgroundNoise />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/RemoveBackgroundNoise"
          element={
            <React.Suspense fallback={<Loading />}>
              <RemoveBackgroundNoise />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/RemoveBackgroundNoise"
          element={
            <React.Suspense fallback={<Loading />}>
              <RemoveBackgroundNoise />
            </React.Suspense>
          }
        />
        {/* //旋转视频 */}
        <Route
          path="/RotateVideo"
          element={
            <React.Suspense fallback={<Loading />}>
              <RotateVideo />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/RemoveBackgroundNoise"
          element={
            <React.Suspense fallback={<Loading />}>
              <RotateVideo />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/RemoveBackgroundNoise"
          element={
            <React.Suspense fallback={<Loading />}>
              <RotateVideo />
            </React.Suspense>
          }
        />
        {/* //视频压缩 */}
        <Route
          path="/VideoCompress"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoCompress />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/VideoCompress"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoCompress />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/VideoCompress"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoCompress />
            </React.Suspense>
          }
        />
        {/* //视频切割 */}
        <Route
          path="/VideoCutter"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoCutter />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/VideoCutter"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoCutter />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/VideoCutter"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoCutter />
            </React.Suspense>
          }
        />

        <Route
          path="/VideoCutter/CutterWorkSpace"
          element={
            <React.Suspense fallback={<Loading />}>
              <CutterWorkSpace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/VideoCutter/CutterWorkSpace"
          element={
            <React.Suspense fallback={<Loading />}>
              <CutterWorkSpace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/VideoCutter/CutterWorkSpace"
          element={
            <React.Suspense fallback={<Loading />}>
              <CutterWorkSpace />
            </React.Suspense>
          }
        />
        {/* //视频编辑器过度 */}
        <Route
          path="/VideoEditorEffects"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoEditorEffects />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/VideoEditorEffects"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoEditorEffects />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/VideoEditorEffects"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoEditorEffects />
            </React.Suspense>
          }
        />
        {/* //合并视频 */}
        <Route
          path="/VideoEditorTransition"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoEditorTransition />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/VideoEditorTransition"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoEditorTransition />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/VideoEditorTransition"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoEditorTransition />
            </React.Suspense>
          }
        />
        {/* 合并视频 */}
        <Route
          path="/VideoMerge"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoMerge />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/VideoMerge"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoMerge />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/VideoMerge"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoMerge />
            </React.Suspense>
          }
        />
        <Route
          path="/VideoMerge/VideoMergeWorkspace"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoMergeWorkspace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/VideoMerge/VideoMergeWorkspace"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoMergeWorkspace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/VideoMerge/VideoMergeWorkspace"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoMergeWorkspace />
            </React.Suspense>
          }
        />
        {/* 合并视频 */}
        <Route
          path="/VideoFilters"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoFilters />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/VideoFilters"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoFilters />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/VideoFilters"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoFilters />
            </React.Suspense>
          }
        />

        {/* //视频帧速率转换器 */}
        <Route
          path="/VideoFrameRateConverter"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoFrameRateConverter />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/VideoFrameRateConverter"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoFrameRateConverter />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/VideoFrameRateConverter"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoFrameRateConverter />
            </React.Suspense>
          }
        />
        {/* //视频覆盖 */}
        <Route
          path="/VideoOverlay"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoOverlay />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/VideoOverlay"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoOverlay />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/VideoOverlay"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoOverlay />
            </React.Suspense>
          }
        />
        {/* //视频拆分 */}
        <Route
          path="/VideoSpliter"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoSpliter />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/VideoOverlay"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoSpliter />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/VideoSpliter"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoSpliter />
            </React.Suspense>
          }
        />
        {/* //视频静音 */}
        <Route
          path="/MuteVideo"
          element={
            <React.Suspense fallback={<Loading />}>
              <MuteVideo />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/MuteVideo"
          element={
            <React.Suspense fallback={<Loading />}>
              <MuteVideo />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/MuteVideo"
          element={
            <React.Suspense fallback={<Loading />}>
              <MuteVideo />
            </React.Suspense>
          }
        />
        {/* //视频速度控制 */}
        <Route
          path="/VideoSpeedController"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoSpeedController />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/VideoSpeedController"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoSpeedController />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/VideoSpeedController"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoSpeedController />
            </React.Suspense>
          }
        />
        <Route
          path="/VideoSpeedController/VideoSpeedControllerWorkspace"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoSpeedControllerWorkspace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/VideoSpeedController/VideoSpeedControllerWorkspace"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoSpeedControllerWorkspace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/VideoSpeedController/VideoSpeedControllerWorkspace"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoSpeedControllerWorkspace />
            </React.Suspense>
          }
        />
        {/* //视频导出音频 */}
        <Route
          path="/VideoToAudio"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToAudio />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/VideoToAudio"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToAudio />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/VideoToAudio"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToAudio />
            </React.Suspense>
          }
        />
        {/* //视频导成GIF图片 */}
        <Route
          path="/VideoToGif"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToGif />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/VideoToGif"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToGif />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/VideoToGif"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToGif />
            </React.Suspense>
          }
        />
        {/* //视频倒放 */}
        <Route
          path="/ReverseVideo"
          element={
            <React.Suspense fallback={<Loading />}>
              <ReverseVideo />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/ReverseVideo"
          element={
            <React.Suspense fallback={<Loading />}>
              <ReverseVideo />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/ReverseVideo"
          element={
            <React.Suspense fallback={<Loading />}>
              <ReverseVideo />
            </React.Suspense>
          }
        />
        {/* //GIF转视频 */}
        <Route
          path="/GIFToVideo"
          element={
            <React.Suspense fallback={<Loading />}>
              <GIFToVideo />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/GIFToVideo"
          element={
            <React.Suspense fallback={<Loading />}>
              <GIFToVideo />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/GIFToVideo"
          element={
            <React.Suspense fallback={<Loading />}>
              <GIFToVideo />
            </React.Suspense>
          }
        />
        {/* //视频翻译 */}
        <Route
          path="/VideoTranslator"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoTranslator />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/VideoTranslator"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoTranslator />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/VideoTranslator"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoTranslator />
            </React.Suspense>
          }
        />
        {/* //视频转mp4 */}
        <Route
          path="/VideoToMp4"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToMp4 />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/VideoToMp4"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToMp4 />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/VideoToMp4"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToMp4 />
            </React.Suspense>
          }
        />
        {/* //视频转avi */}
        <Route
          path="/VideoToAvi"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToAvi />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/VideoToAvi"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToAvi />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/VideoToAvi"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToAvi />
            </React.Suspense>
          }
        />
        {/* //视频转webm */}
        <Route
          path="/VideoToWebm"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToWebm />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/VideoToWebm"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToWebm />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/VideoToWebm"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToWebm />
            </React.Suspense>
          }
        />
        {/* //视频转mpeg */}
        <Route
          path="/VideoToMpeg"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToMpeg />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/VideoToMpeg"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToMpeg />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/VideoToMpeg"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToMpeg />
            </React.Suspense>
          }
        />
        {/* //视频转wmv */}
        <Route
          path="/VideoToWmv"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToWmv />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/VideoToWmv"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToWmv />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/VideoToWmv"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToWmv />
            </React.Suspense>
          }
        />
        {/* //视频转mov */}
        <Route
          path="/VideoToMov"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToMov />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/VideoToMov"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToMov />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/VideoToMov"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToMov />
            </React.Suspense>
          }
        />
        {/* //视频转mpg */}
        <Route
          path="/VideoToMpg"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToMpg />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/VideoToMpg"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToMpg />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/VideoToMpg"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToMpg />
            </React.Suspense>
          }
        />
        {/* //视频转3pg */}
        <Route
          path="/VideoTo3gp"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoTo3gp />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/VideoTo3gp"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoTo3gp />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/VideoTo3gp"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoTo3gp />
            </React.Suspense>
          }
        />
        {/* //视频转mkv */}
        <Route
          path="/VideoToMkv"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToMkv />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/VideoToMkv"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToMkv />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/VideoToMkv"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToMkv />
            </React.Suspense>
          }
        />
        {/* //视频转flv */}
        <Route
          path="/VideoToFlv"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToFlv />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/VideoToFlv"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToFlv />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/VideoToFlv"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToFlv />
            </React.Suspense>
          }
        />
        {/* videoCollage */}
        <Route
          path="/VideoCollage/VideoCollageWorkspace"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoCollageWorkspace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/VideoCollage/VideoCollageWorkspace"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoCollageWorkspace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/VideoCollage/VideoCollageWorkspace"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoCollageWorkspace />
            </React.Suspense>
          }
        />
        {/* //视频转vob */}
        <Route
          path="/VideoToVob"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToVob />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/VideoToVob"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToVob />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/VideoToVob"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoToVob />
            </React.Suspense>
          }
        />
        {/* AutoCutTemplate */}
        <Route
          path="/AutoCutTemplate"
          element={
            <React.Suspense fallback={<Loading />}>
              <AutoCutTemplate />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/AutoCutTemplate"
          element={
            <React.Suspense fallback={<Loading />}>
              <AutoCutTemplate />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/AutoCutTemplate"
          element={
            <React.Suspense fallback={<Loading />}>
              <AutoCutTemplate />
            </React.Suspense>
          }
        />
        {/* AutoCutPreview */}
        <Route
          path="/AutoCutTemplate/AutoCutPreview"
          element={
            <React.Suspense fallback={<Loading />}>
              <AutoCutPreview />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/AutoCutTemplate/AutoCutPreview"
          element={
            <React.Suspense fallback={<Loading />}>
              <AutoCutPreview />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/AutoCutTemplate/AutoCutPreview"
          element={
            <React.Suspense fallback={<Loading />}>
              <AutoCutPreview />
            </React.Suspense>
          }
        />
        {/* AutoCutChoose */}
        <Route
          path="/AutoCutTemplate/AutoCutPreview/AutoCutChoose"
          element={
            <React.Suspense fallback={<Loading />}>
              <AutoCutChoose />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/AutoCutTemplate/AutoCutPreview/AutoCutChoose"
          element={
            <React.Suspense fallback={<Loading />}>
              <AutoCutChoose />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/AutoCutTemplate/AutoCutPreview/AutoCutChoose"
          element={
            <React.Suspense fallback={<Loading />}>
              <AutoCutChoose />
            </React.Suspense>
          }
        />
        {/* Middle */}
        <Route
          path="/AutoCutTemplate/AutoCutPreview/AutoCutChoose/Middle"
          element={
            <React.Suspense fallback={<Loading />}>
              <UploadAndGet />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/AutoCutTemplate/AutoCutPreview/AutoCutChoose/Middle"
          element={
            <React.Suspense fallback={<Loading />}>
              <UploadAndGet />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/AutoCutTemplate/AutoCutPreview/AutoCutChoose/Middle"
          element={
            <React.Suspense fallback={<Loading />}>
              <UploadAndGet />
            </React.Suspense>
          }
        />
        {/* PreviewResult */}
        <Route
          path="/autocut/download"
          element={
            <React.Suspense fallback={<Loading />}>
              <PreviewResult />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/autocut/download"
          element={
            <React.Suspense fallback={<Loading />}>
              <PreviewResult />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/autocut/download"
          element={
            <React.Suspense fallback={<Loading />}>
              <PreviewResult />
            </React.Suspense>
          }
        />
        {/* newResult */}
        <Route
          path="/VideoEditorWorkSpace"
          element={
            <React.Suspense fallback={<Loading />}>
              <VideoEditorWorkSpace />
            </React.Suspense>
          }
        />
        <Route
          path="/Convert/Result"
          element={
            <React.Suspense fallback={<Loading />}>
              <Result />
            </React.Suspense>
          }
        />
        <Route
          path="/Share/Result"
          element={
            <React.Suspense fallback={<Loading />}>
              <Result />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/Convert/Result"
          element={
            <React.Suspense fallback={<Loading />}>
              <Result />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/Convert/Result"
          element={
            <React.Suspense fallback={<Loading />}>
              <Result />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/:tag3/Convert/Result"
          element={
            <React.Suspense fallback={<Loading />}>
              <Result />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/:tag3/:tag4/Convert/Result"
          element={
            <React.Suspense fallback={<Loading />}>
              <Result />
            </React.Suspense>
          }
        />
        <Route
          path="/Subscribe"
          element={
            <React.Suspense fallback={<Loading />}>
              <SubscribePage />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/Subscribe"
          element={
            <React.Suspense fallback={<Loading />}>
              <SubscribePage />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/Subscribe"
          element={
            <React.Suspense fallback={<Loading />}>
              <SubscribePage />
            </React.Suspense>
          }
        />
        <Route
          path="/Profile"
          element={
            <React.Suspense fallback={<Loading />}>
              <Profile />
            </React.Suspense>
          }
        >
          <Route
            index
            element={
              <React.Suspense fallback={<Loading />}>
                <ProfileContent />
              </React.Suspense>
            }
          />
          <Route
            path="resetPasswordByOldPassword"
            element={
              <React.Suspense fallback={<Loading />}>
                <ResetPasswordByOldPassword />
              </React.Suspense>
            }
          />
          <Route
            path="plan"
            element={
              <React.Suspense fallback={<Loading />}>
                <ProfileContent />
              </React.Suspense>
            }
          />
        </Route>
        <Route
          path=":tag/Profile"
          element={
            <React.Suspense fallback={<Loading />}>
              <Profile />
            </React.Suspense>
          }
        >
          <Route
            index
            element={
              <React.Suspense fallback={<Loading />}>
                <ProfileContent />
              </React.Suspense>
            }
          />
          <Route
            path="resetPasswordByOldPassword"
            element={
              <React.Suspense fallback={<Loading />}>
                <ResetPasswordByOldPassword />
              </React.Suspense>
            }
          />
          <Route
            path="plan"
            element={
              <React.Suspense fallback={<Loading />}>
                <ProfileContent />
              </React.Suspense>
            }
          />
        </Route>
        <Route
          path=":tag1/:tag2/Profile"
          element={
            <React.Suspense fallback={<Loading />}>
              <Profile />
            </React.Suspense>
          }
        >
          <Route
            index
            element={
              <React.Suspense fallback={<Loading />}>
                <ProfileContent />
              </React.Suspense>
            }
          />
          <Route
            path="resetPasswordByOldPassword"
            element={
              <React.Suspense fallback={<Loading />}>
                <ResetPasswordByOldPassword />
              </React.Suspense>
            }
          />
          <Route
            path="plan"
            element={
              <React.Suspense fallback={<Loading />}>
                <ProfileContent />
              </React.Suspense>
            }
          />
        </Route>
        {/* Support页面 */}
        <Route
          path="/Support"
          element={
            <React.Suspense fallback={<Loading />}>
              <Support />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/Support"
          element={
            <React.Suspense fallback={<Loading />}>
              <Support />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/Support"
          element={
            <React.Suspense fallback={<Loading />}>
              <Support />
            </React.Suspense>
          }
        />
        {/* 忘记密码 */}
        <Route
          path="/ForgetPassWeb"
          element={
            <React.Suspense fallback={<Loading />}>
              <ForgetPassWeb />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/ForgetPassWeb"
          element={
            <React.Suspense fallback={<Loading />}>
              <ForgetPassWeb />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/ForgetPassWeb"
          element={
            <React.Suspense fallback={<Loading />}>
              <ForgetPassWeb />
            </React.Suspense>
          }
        />
        {/* 找不到页面 */}
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Routes>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isOpenSnackbar.open}
        autoHideDuration={6000}
        onClose={handleClose}
        sx={{
          zIndex: 9999,
        }}
      >
        <Alert onClose={handleClose} severity={isOpenSnackbar.severity} sx={{ width: "100%" }}>
          <p
            style={{
              fontSize: "1rem",
            }}
          >
            {isOpenSnackbar.message}
          </p>
        </Alert>
      </Snackbar>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "100vw",
            height: "100vh",
            background: "#ffffff",
            zIndex: "99",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <div className="k-lineA-1 k-line "></div>
            <div className="k-lineA-2 k-line "></div>
            <div className="k-lineA-3 k-line "></div>
            {/* <div className="k-lineA-4 k-line "></div>
            <div className="k-lineA-5 k-line "></div> */}
          </div>
        </div>
      )}
    </BrowserRouter>
  );
}
