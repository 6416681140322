import React from "react";
// MUI
// import { Box,  Typography } from "@mui/material";
import "../../../css/animation.css";
export default function Uploading() {
  return (
    <div style={{ margin: "auto", width: "100px", paddingTop: "160px" }}>
      <img
        src="/images/icon/icon_upload.svg"
        style={{
          width: "100%",
          display: "block",
          animation: "turn 1s linear infinite",
        }}
      />
      <p
        style={{
          fontSize: "25px",
          paddingTop: "90px",
          color: "rgba(50, 51, 57, 0.6)",
          textAlign: "center",
        }}
      >
        loading...
      </p>
    </div>
  );
}
