import { useState, useEffect } from "react";
import { get, set } from "idb-keyval";
// 跨域存储配置
import storage from "../storage";
// LocalStorage状态管理方法
export function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      log(error);
    }
  };
  return [storedValue, setValue];
}
/**
 * 跨域存储,类 useState 的变量创建方法
 * @param {String} key 用于在数据库中识别变量的key
 * @param {*} initialValue 变量默认值
 * @returns {Array} crossStorageHook
 * @returns {*} crossStorageHook[0] 声明变量
 * @returns {Function} crossStorageHook[1] 声明变量的修改方法
 * @returns {Function} crossStorageHook[2] 声明变量的删除方法
 */
export function useCrossStorage(key, initialValue) {
  // 通过 useState 声明变量
  const [storedValue, setStoredValue] = useState(initialValue);
  // 数据初始化,尝试获取数据库中存储的值
  useEffect(() => {
    (async () => {
      if (
        (/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)) ||
        /iPod|iPhone|iPad/.test(navigator.platform)
      ) {
        const value = await get(key);
        setStoredValue(value === null ? initialValue : value);
      } else {
        try {
          await storage.onConnect();
          const value = await storage.get(key);
          setStoredValue(value === null ? initialValue : value);
        } catch (e) {
          loge(e);
        }
      }
    })();
    return () => {
      logi(storedValue);
    };
  }, []);
  // 定义修改变量方法
  const setValue = async (value) => {
    if (
      (/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)) ||
      /iPod|iPhone|iPad/.test(navigator.platform)
    ) {
      setStoredValue(value);
      set(key, value)
        .then(() => log("It worked!"))
        .catch((err) => log("It failed!", err));
    } else {
      try {
        setStoredValue(value);
        await storage.onConnect();
        await storage.set(key, value);
        return value;
      } catch (e) {
        loge(e);
      }
    }
  };
  // 定义获取变量方法
  const getValue = async () => {
    if (
      (/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)) ||
      /iPod|iPhone|iPad/.test(navigator.platform)
    ) {
      const value = await get(key);
      setStoredValue(value === null ? initialValue : value);
      return value === null ? initialValue : value;
    } else {
      try {
        await storage.onConnect();
        const value = await storage.get(key);
        setStoredValue(value === null ? initialValue : value);
        return value === null ? initialValue : value;
      } catch (e) {
        loge(e);
        return initialValue;
      }
    }
  };
  // 定义删除变量方法
  // const removeValue = async () => {
  //   try {
  //     setStoredValue(null);
  //     await storage.onConnect();
  //     await storage.del(key);
  //     return storedValue;
  //   } catch (e) {
  //     loge(e);
  //   }
  // };
  return [storedValue, setValue, getValue];
}
export default { useLocalStorage, useCrossStorage };
