// 跨域存储配置
import storage from "../storage";
import { get, set } from "idb-keyval";

export default async function getLanguage() {
  if (
    (/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)) ||
    /iPod|iPhone|iPad/.test(navigator.platform)
  ) {
    const value = await get("user_language");
    return {
      name: "crossStorageDetector",
      lookup(options) {
        return value;
      },
      cacheUserLanguage(lng, options) {},
    };
  } else {
    await storage.onConnect();
    const value = await storage.get("user_language");
    return {
      name: "crossStorageDetector",
      lookup(options) {
        return value;
      },
      cacheUserLanguage(lng, options) {},
    };
  }
}
