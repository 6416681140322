// 全局状态管理
// 功能引入
import React from "react";
import { createContext, useEffect, useState } from "react";
// hook 工具,跨域存储
import { useCrossStorage } from "./hookTools";
// 国际化组件,定位用户语言并完成初始化
import { loadLocale } from "../internationalization/internationalization";
// 获取用户免费使用次数有效期(过期后重置次数)
const FREE_TIMES_EFFECTIVE = process.env.REACT_APP_FREE_TIMES_EFFECTIVE || 72000000;
// 创建 Context 对象
const GlobalContext = createContext();
// 封装 ContextProvider
function GlobalContextProvider({ children }) {
  // ******** 全局loading事件 ********
  let [isLoading, setIsLoading] = useState(false);
  // 用户选择的格式
  const [videoConvertFormat, setVideoConvertFormat, getVideoConvertFormat] = useCrossStorage(
    "video_convert_format",
    null,
  );
  // 国际化组件加载状态
  const [languageReady, setLanguageReady] = useState(false);
  // 创建全局状态
  // 用户名
  const [userName, setUserName, getUserName] = useCrossStorage("user_name", null);
  // 用户登录凭证
  const [userToken, setUserToken, getUserToken] = useCrossStorage("user_token", null);
  // 用户订阅状态
  const [userSubState, setUserSubState, getUserSubState] = useCrossStorage("user_sub_state", null);
  // 用户订阅计划
  const [userSubPlan, setUserSubPlan, getUserSubPlan] = useCrossStorage("user_sub_plan", null);
  // 用户免费使用次数
  const [freeTimes, setFreeTimes, getFreeTimes] = useCrossStorage("free_times", null);
  // 老用户标识
  const [oldUser, setOldUser, getOldUser] = useCrossStorage("old_user", null);
  // 上传文件超过1G提示
  const [modelOpen, setModelOpen] = useState(false);

  // 用户选择上传的文件
  const [uploadFile, setUploadFiles, getUploadFiles] = useCrossStorage("user_file", null);
  // 用户即将下载的文件(处理后的文件)
  const [downloadFile, setDownloadFile, getDownloadFile] = useCrossStorage("download_file", null);
  //用户结果页显示的文件
  const [playMp4File, setPlayMp4File, getPlayMp4File] = useCrossStorage("play_file", null);
  //用户选择的编辑工具
  const [videoEditorTool, setVideoEditorTool, getVideoEditorTool] = useCrossStorage(
    "editorTool",
    null,
  );
  // 用户最后访问的页面
  const [lastReferrer, setLastReferrer, getLastReferrer] = useCrossStorage("lastReferrer", {
    previousPage: "/",
    currentPage: "/",
  });
  // 用户当前使用工具的信息
  const [informationConvert, setInformationConvert, getInformationConvert] = useCrossStorage(
    "informationConvert",
    null,
  );

  // 用户语言
  const [language, setLanguage, getLanguage] = useCrossStorage("user_language", null);
  // videoEditor Setting
  const [videoEditorSetting, setVideoEditorSetting, getVideoEditorSetting] = useCrossStorage(
    "video_editor_setting",
    {
      size: "origin",
      isMute: false,
      duration: "auto",
    },
  );
  // media
  const [media, setMedia, getMedia] = useCrossStorage("media", {
    rotation: 0,
    flipX: false,
    flipY: false,
    videoSpeed: 1,
    videoVolume: 100,
  });
  // audio
  const [audio, setAudio, getAudio] = useCrossStorage("audio", {
    isMute: false,
    volume: 100,
    isFadeIn: false,
    isFadeOut: false,
    speed: 1,
    duration: {
      start: "00:00:00",
      end: "00:00:00",
    },
  });
  // 存储上传的字幕文件的信息
  const [uploadSubtitle, setUploadSubtitle] = useState([]);
  // 存储画布
  const [timeTrackCanvas, setTimeTrackCanvas] = useState({});
  // 存储视频画布
  const [videoCanvas, setVideoCanvas] = useState({});
  // 哪一个文字正在编辑
  const [whichTextIsEdit, setWhichTextIsEdit] = useState({});
  // 编辑哪一个轨道
  const [whichTrackIsEdit, setWhichTrackIsEdit] = useState({});

  // 存储视频文件
  // 单纯的存储文件
  // 仅仅用来标记位置
  let [videoTrack, setVideoTrack] = useState([]);
  // 存储视频的json
  let [videoPlayParm, setVideoPlayParm] = useState({});
  // 获取mira miraPlayer
  let [miraPlayer, setMiraPlayer] = useState();
  // router弹窗
  let [isOpenSnackbar, setIsOpenSnackbar] = useState({
    open: false,
    message: "Please make sure that there is data on the main track",
    severity: "warning",
  });
  // 全局进度条
  let [globalProgress, setGlobalProgress] = useState(0);
  // ******** 剪同款 start ********
  // 选择的模板
  const [cutSameTemplate, setAutoCutTemplate, getAutoCutTemplate] = useCrossStorage(
    "cut_same_template",
    null,
  );
  // 存储下载链接
  let [downloadURL, setDownloadURL, getDownloadURL] = useCrossStorage("download_url", "");
  // 剪辑和上传之间的数据
  let [chooseVideo, setChooseVideo, getChooseVideo] = useCrossStorage("choose_video", {});
  // ******** 剪同款 end ********
  // 保存全局状态
  const globalState = {
    // 用户名,即用户邮箱
    userName,
    getUserName,
    setUserName,
    // 用户登录凭证
    userToken,
    getUserToken,
    setUserToken,
    // 用户订阅状态
    userSubState,
    getUserSubState,
    setUserSubState,
    // 用户订阅计划
    userSubPlan,
    getUserSubPlan,
    setUserSubPlan,
    // 用户免费使用次数
    freeTimes,
    getFreeTimes,
    setFreeTimes,
    // 老用户标识
    oldUser,
    getOldUser,
    setOldUser,
    modelOpen,
    setModelOpen,
    // 用户选择上传的文件
    uploadFile,
    getUploadFiles,
    setUploadFiles,
    // 用户即将下载的文件
    downloadFile,
    getDownloadFile,
    setDownloadFile,
    //用户播放的文件
    playMp4File,
    setPlayMp4File,
    getPlayMp4File,
    //用户选择的工具信息
    videoEditorTool,
    setVideoEditorTool,
    getVideoEditorTool,
    // 用户最后访问的页面
    lastReferrer,
    setLastReferrer,
    getLastReferrer,
    // 用户当前使用工具信息
    informationConvert,
    setInformationConvert,
    getInformationConvert,
    // 用户语言
    language,
    setLanguage,
    getLanguage,
    // 视频转换的目标格式
    videoConvertFormat,
    setVideoConvertFormat,
    getVideoConvertFormat,
    // 视频编辑界面 设置界面
    videoEditorSetting,
    setVideoEditorSetting,
    getVideoEditorSetting,
    // 记录视频轨道的位置
    videoTrack,
    setVideoTrack,
    // media 信息
    media,
    setMedia,
    getMedia,
    // audio
    audio,
    setAudio,
    getAudio,
    // 存储上传的字幕文件的信息
    uploadSubtitle,
    setUploadSubtitle,
    // 存储轨道画布
    timeTrackCanvas,
    setTimeTrackCanvas,
    // 存储视频画布
    videoCanvas,
    setVideoCanvas,
    // 哪一个文字正在编辑
    whichTextIsEdit,
    setWhichTextIsEdit,
    // video数据的JSON
    videoPlayParm,
    setVideoPlayParm,
    miraPlayer,
    setMiraPlayer,
    // 正在编辑哪一个轨道
    whichTrackIsEdit,
    setWhichTrackIsEdit,
    // 消息通知的控制
    isOpenSnackbar,
    setIsOpenSnackbar,
    // 全局进度条
    globalProgress,
    setGlobalProgress,
    isLoading,
    setIsLoading,
    // 选择剪同款模板
    cutSameTemplate,
    setAutoCutTemplate,
    getAutoCutTemplate,
    downloadURL,
    setDownloadURL,
    getDownloadURL,
    chooseVideo,
    setChooseVideo,
    getChooseVideo,
  };
  useEffect(async () => {
    // 清空字幕
    await setUploadSubtitle([]);
  }, []);
  // 页面初始化
  useEffect(() => {
    (async () => {
      React.setGlobalProgress = setGlobalProgress;
      // 查看用户订阅计划是否缓存,如果没有则通过服务器获取用户订阅计划
      // const subPlanCache = await getUserSubPlan();
      // logi("subPlanCache:", JSON.stringify(subPlanCache));
      // if (!subPlanCache) {
      //   const serverSubPlan = await syncServerSubscriptionPlan();
      //   if (serverSubPlan.status === "success") {
      //     setUserSubPlan(serverSubPlan.response);
      //   }
      // }
      // 查看用户免费次数是否缓存,如果超时或没有缓存则重置用户免费次数
      const freeTimesCache = await getFreeTimes();
      logi("freeTimesCache:", JSON.stringify(freeTimesCache));
      const nowTime = new Date().getTime();
      if (freeTimesCache && freeTimesCache.expiration && freeTimesCache.createTime) {
        const nowDate = new Date().getDate();
        const createDate = new Date(freeTimesCache.createTime).getDate();
        const isExpiration =
          nowTime - FREE_TIMES_EFFECTIVE > freeTimesCache.expiration || createDate != nowDate;
        if (isExpiration) {
          await setFreeTimes({
            createTime: nowTime,
            expiration: nowTime + FREE_TIMES_EFFECTIVE,
            times: 0,
          });
        }
      } else {
        await setFreeTimes({
          createTime: nowTime,
          expiration: nowTime + FREE_TIMES_EFFECTIVE,
          times: 0,
        });
      }
      const lastReferrerCache = await getLastReferrer();
      const currentPage = window.location.pathname;
      const previousPage = lastReferrerCache?.currentPage ? lastReferrerCache.currentPage : "/";
      await setLastReferrer({ previousPage, currentPage });
      log("lastReferrer", { previousPage, currentPage });
      // 等待国际化组件初始化
      await loadLocale();
      setLanguageReady(true);
    })();
  }, []);
  //当 userToken 发生变化的时候,即用户登录登出时,重新设置用户属性
  useEffect(() => {
    (async () => {
      // 获取当前浏览器可见区域的宽高
      const windowWidth = window.document.documentElement.clientWidth;
      const windowHeight = window.document.documentElement.clientHeight;
      // // 查看用户登录凭证是否缓存
      // const userTokenCache = await getUserToken();
      // if (userTokenCache) {
      //   // 同步用户订阅状态
      //   const userSub = await syncUserSubscriptionState({
      //     userToken: userTokenCache,
      //   });
      //   // 同步成功保存用户订阅状态
      //   if (userSub.status === "success" && userSub.response) {
      //     setUserSubState(userSub.response);
      //   }
      //   // 根据用户订阅状态设置不同用户属性
      //   if (userSub?.response.status === "ACTIVE") {
      //     setConstructEventUserProperties({
      //       user_vip_state: userSub.response.billCycle,
      //       screen_size: windowWidth + "*" + windowHeight,
      //     });
      //   } else {
      //     setConstructEventUserProperties({
      //       user_vip_state: "NONE",
      //       screen_size: windowWidth + "*" + windowHeight,
      //     });
      //   }
      // } else {
      //   setConstructEventUserProperties({
      //     user_vip_state: "NONE",
      //     screen_size: windowWidth + "*" + windowHeight,
      //   });
      // }
    })();
  }, [userToken]);
  // 发布全局状态
  return (
    <>
      {languageReady && (
        <GlobalContext.Provider value={globalState}>{children}</GlobalContext.Provider>
      )}
    </>
  );
}
// 抛出方法
// 在被GlobalContextProvider包裹的组件中通过引入GlobalContext获取全局状态
// GlobalContextProvider会向其包裹的子组件内部发布全局状态
export { GlobalContext, GlobalContextProvider };
