import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Trans } from "react-i18next/icu.macro";
import Link from "./Link";
export default function HomeStep() {
  const infoTitle1 = "Flexible Editing, Stunning Clips";
  const infoMsg1 =
    "Easily create and edit videos for the brand, marketing, social media, family, and any other purpose.";
  const HomeSteps = [
    // {
    //   title: "Video Editor",
    //   subtitle:
    //     "Edit with your heart. Effortlessly cut, trim, and customize a video, or search related pro clips for various editing.",
    //   svg: "/images/videoEditor.svg",
    //   link: "Start trying ->",
    //   path: "/AddSubtitles",
    // },
    {
      title: "Add subtitles",
      subtitle: "Subtitle a video manually or with an SRT file.",
      svg: "/images/videoEditor.svg",
      link: "Start trying ->",
      path: "/AddSubtitles",
    },
    {
      title: "Video Converter",
      subtitle:
        "You can convert videos, images, audio files, or e-books. There are tons of Advanced Options to fine-tune your conversions.",
      svg: "/images/videoConvert.svg",
      link: "Start conversion ->",
      path: "/VideoToAvi",
    },
    {
      title: "Compress Video",
      subtitle:
        "The tool will do everything for you, just upload your file and it'll set the best parameters to reduce its size and to keep quality as much as possible.",

      svg: "/images/compress.svg",
      link: "Start Compress ->",
      path: "/VideoCompress",
    },
  ];

  return (
    <React.Fragment>
      {/* 上面背景色 */}
      <Box sx={{ height: "300px", marginTop: "100px" }} textAlign="center" className="homeStep">
        <Box
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            backgroundColor: "#3291ff",
            zIndex: "-1",
          }}
        >
          <Box
            sx={{
              color: "#fff",
              textAlign: "center",
              paddingTop: "40px",
              position: "relative",
            }}
          >
            {/* title */}
            <Typography
              lineHeight="63px"
              positive="relative"
              variant="h3"
              component="span"
              sx={{ fontWeight: 500 }}
            >
              {/* <Trans i18nKey={infoTitle1}> */}
              <strong> {infoTitle1} </strong>
              {/* </Trans> */}
            </Typography>
            {/* subtitle */}
            <Typography
              fontSize="20px"
              lineHeight="25px"
              positive="relative"
              padding="40px 0"
              margin="auto"
              maxWidth="678px"
              width="100%"
              sx={{
                color: "#fff",
              }}
            >
              <Trans i18nKey={infoMsg1}>{infoMsg1}</Trans>
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          paddingTop: "100px",
          margin: "0 auto",
          width: "100%",
          "& :nth-of-type(2n+1)": { flexDirection: "row" },
          "& :nth-of-type(2n)": { flexDirection: "row-reverse" },
        }}
      >
        {HomeSteps.map((item, index) => (
          <Grid
            key={index}
            container
            columns={{ xs: 6, lg: 12 }}
            sx={{ backgroundColor: "#fff", marginBottom: "60px" }}
          >
            <Grid item xs={6}>
              <Box textAlign="left" paddingLeft="25px" sx={{ marginTop: "40px" }}>
                {/* title */}
                <Box>
                  <Typography
                    position="relative"
                    lineHeight="50px"
                    variant="subtitle2"
                    sx={{ fontWeight: 500 }}
                    positive="relative"
                    component="span"
                  >
                    {/* <Trans i18nKey={item.title}> */}
                    <strong>{item.title}</strong>
                    {/* </Trans> */}
                  </Typography>
                </Box>
                {/* subtitle */}
                <Typography
                  position="relative"
                  paddingTop="24px"
                  lineHeight="30px"
                  maxWidth="480px"
                  width="100%"
                  variant="body5"
                  sx={{
                    fontWeight: 500,
                  }}
                >
                  {/* <Trans i18nKey={item.subtitle}> */}
                  <strong>{item.subtitle}</strong>
                  {/* </Trans> */}
                </Typography>
                <Box>
                  <Link
                    href={item.path}
                    position="step"
                    onClick={() => {
                      sendConstructEventByEventParams("home", {
                        click_step: item.link,
                      });
                    }}
                    style={{ color: "#3291ff", lineHeight: "25px" }}
                  >
                    <Typography variant="body5">
                      <Trans i18nKey={item.link}>{item.link}</Trans>
                    </Typography>
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <img
                style={{
                  width: "100%",
                  maxWidth: "1200px",
                  height: "auto",
                  margin: "auto",
                }}
                src={item.svg}
              />
            </Grid>
          </Grid>
        ))}
      </Box>
    </React.Fragment>
  );
}
